/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Company video  Modal  for adding, editing Videos and API wiring up
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 05/Aug/2020 
	Author						: Anand Kumar
================================================================ 
*/

import React, { useState, useEffect, useRef } from "react";
import ReactTooltip from "react-tooltip";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { setSideNavForcedActiveLink } from "../../../../../middleware/actions/sideNavAction";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, TextEditor, Dropdown, NumericTextField, InputGroupText } from "../../../../components/CustomFormElements/syncFusionFormFields";
import { videoValidationSchema } from "../../../../helper/validationSchema/companyValidationSchema";
import { fetchVideoById, editVideo, saveVideo, fetchVideoThumbUrl } from "../../../../../middleware/services/companyApi";
import { Modal } from "react-bootstrap";
import { actionSuccess, actionError } from "../../../../../middleware/actions/utilityAction";
import { ckEditorMinimalConfig } from "../../../../../config/ckEditorConfig";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";

// main video model component
const VideoModal = (props) => {
  const [oldData, setOldData] = useState(null);
  const videoThumbRef = useRef(null);
  const videoThumbInputRef = useRef(null);
  const [videoThumb, setVideoThumb] = useState(undefined);
  const [imageError, setImageError] = useState("");

  const initialFormValues = {
    videoTitle: "",
    videoDescription: "",
    videoSource: "Vimeo",
    videoId: "",
    videoDurationInMinute: "",
    videoThumbnail: "",
  };

  const formik = useFormik({
    initialValues: {
      ...initialFormValues,
    },
    validationSchema: Yup.object().shape(videoValidationSchema),
  });

  const readVideoThumnail = (imageFile) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      videoThumbRef.current.setAttribute("src", e.target.result);
    };
    reader.readAsDataURL(imageFile);
  };

  const handleReset = () => {
    props.closeHandler();
  };
  const onSaveData = (companyId, data) => {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    saveVideo(companyId, formData)
      .then((result) => {
        if (result.status === 200) {
          dispatch(actionSuccess("Video saved successfully"));
          handleReset();
        }
      })
      .catch((err) => {
        dispatch(actionError(err?.data?.message || "Something went wrong"));
      });
  };

  const onUpdateData = (companyId, editVideoId, data) => {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    editVideo(companyId, editVideoId, formData)
      .then((result) => {
        if (result.status === 200) {
          dispatch(actionSuccess("Video updated successfully"));
          handleReset();
        }
      })
      .catch((err) => {
        dispatch(actionError(err?.data?.message || "Something went wrong"));
      });
  };

  const setFormikValues = (data) => {
    const oldData = {
      videoTitle: data.videoTitle ? data.videoTitle : "",
      videoDescription: data.videoDescription || "",
      videoSource: data.videoSource || "",
      videoId: data.videoId || "",
      videoDurationInMinute: data.videoDuration || "",
      videoThumbnail: data.videoThumbnail || "",
    };
    setOldData(oldData);
    formik.setValues(oldData);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.editDocumentData) {
      fetchVideoById(props.companyId, props.editVideoId)
        .then((response1) => {
          setFormikValues(response1.data?.data?.companyVideo || []);
        })
        .catch((err) => {
          dispatch(actionError(err?.data?.message || "Something went wrong"));
        });
    } else {
      formik.resetForm();
    }
    return () => {
      dispatch(setSideNavForcedActiveLink());
    };
  }, [props.editDocumentData, props.companyId, props.editVideoId, props.showModal]);

  const saveDocumentData = async () => {
    const { values } = formik;
    const companyVideoData = {
      videoTitle: values.videoTitle,
      videoDescription: values.videoDescription,
      videoSource: values.videoSource,
      videoId: values.videoId,
      videoDuration: values.videoDurationInMinute,
      videoThumbnail: values.videoThumbnail,
    };
    const response = props.editDocumentData
      ? onUpdateData(props.companyId, props.editVideoId, companyVideoData)
      : onSaveData(props.companyId, companyVideoData);
  };
  const handleSubmitDocument = () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        saveDocumentData();
      }
    });
  };
  return (
    <Modal
      show={props.showModal}
      onHide={() => {
        props.closeHandler();
      }}
      backdrop="static"
      className="modal fade lg"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modal-header bg-none text-dark">
        <h5 className="modal-title" id="exampleModalLabel">
          {props.editVideoId ? "Edit" : "Add New"} Video
        </h5>
        <button type="button" className="close" aria-label="Close" onClick={handleReset}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <TextField formik={formik} placeholder="Title *" id="videoTitle" name="videoTitle" required="required" maxLength="150" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <small style={{ position: "absolute" }}>Description</small>
            <TextEditor
              formik={formik}
              properties={{
                placeholder: "Description",
                id: "videoDescription",
                name: "videoDescription",
                value: formik.values.videoDescription,
                config: { ...ckEditorMinimalConfig, editorplaceholder: "Description", height: "200px" },
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Dropdown
              formik={formik}
              required="required"
              placeholder="Video Source"
              id="videoSource"
              name="videoSource"
              //  fields={{ text: "label", value: "value" }}
              dataSource={["Vimeo", "YouTube"]}
            />
          </div>
          <div className="col-md-8 videoInputGroup">
            <InputGroupText
              formik={formik}
              inputGroupText={
                formik.values.videoSource === "Vimeo"
                  ? "https://player.vimeo.com/video/"
                  : (formik.values.videoSource === "YouTube" && "https://www.youtube.com/") || ""
              }
              type="text"
              id="videoId"
              placeholder={`Video ID *`}
              name="videoId"
            />
          </div>
          <div className="col-md-1 p-2">
            <i
              data-tip={`e.g link : https://${
                formik.values.videoSource === "Vimeo" ? "vimeo.com" : "youtube.com"
              }/428464282/899849fa06,<br/> id : 428464282/899849fa06`}
              className="text-muted fa-lg fas fa-question-circle"
            ></i>
            <ReactTooltip multiline={true} delay={1000} />
          </div>
        </div>
        <div className="row ">
          <div className="col-md-4">
            <NumericTextField
              formik={formik}
              format="n0"
              min={0}
              id="videoDurationInMinute"
              placeholder="Duration in Minute *"
              name="videoDurationInMinute"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 pl-3 pb-2">
            <div className="d-flex align-item-left justify-content-left">
              {formik.values.videoThumbnail && (
                <img
                  // style={{ borderRadius: "100px" }}
                  src={formik.values.videoThumbnail && fetchVideoThumbUrl(formik.values.videoThumbnail)}
                  alt="Product"
                  width="146"
                  height="128"
                  ref={videoThumbRef}
                />
              )}
              {formik.values.videoThumbnail && (
                <i
                  className={`fa fa-trash text-muted mr-1 ${!formik.values.videoThumbnail ? "d-none" : undefined}`}
                  aria-hidden="true"
                  onClick={() => {
                    dispatch(
                      showAlertBox({
                        okCallback: async () => {
                          dispatch(actionSuccess("Image deleted successfully"));
                          formik.setValues({ ...formik.values, videoThumbnail: "" });
                          videoThumbInputRef.current.value = "";
                        },
                        okText: "Delete",
                        cancelText: "Cancel",
                        content: "Are you sure you want to Delete ?",
                        title: "dialogAlertCssWarning",
                      })
                    );
                  }}
                />
              )}
              {/* {!formik.values.videoThumb && <i className="fa-10x text-muted fas fa-image"></i>} */}
              {!formik.values.videoThumbnail && <div className="col-md-3 thumbnail"></div>}
              {
                <div className="align-item-left m-3">
                  <button
                    type="button"
                    onClick={() => videoThumbInputRef.current.click()}
                    className="pt-4 pl-0 e-btn e-link text-capitalize text-danger"
                  >
                    Upload Thumbnail *
                  </button>
                  <br />
                  (Image size should be less than 200 KB)
                </div>
              }
            </div>
            <div className="image-action mr-4">
              {/* <i className="fa fa-pencil-alt " aria-hidden="true"
                                            onClick={() => videoThumbInputRef.current.click()}
                                        /> */}
            </div>
            <input
              type="file"
              className="d-none"
              accept=".jpg, .png"
              id="videoThumbnail"
              name="videoThumbnail"
              ref={videoThumbInputRef}
              onChange={(e) => {
                if (e.target.files.length > 0) {
                  if (
                    (e.target.files[0].type == "image/png" || e.target.files[0].type == "image/jpg" || e.target.files[0].type == "image/jpeg") &&
                    e.target.files[0].size < 204800
                  ) {
                    setImageError("");
                    setVideoThumb(e.target.files[0].name);
                    readVideoThumnail(e.target.files[0]);
                    if (videoThumbInputRef.current.files.length)
                      formik.setValues({ ...formik.values, videoThumbnail: videoThumbInputRef.current.files[0] });
                  } else {
                    setVideoThumb("");
                    setImageError("Only JPG, PNG files are allowed. Max file size should be 200 KB.");
                  }
                }
              }}
            />
            <div
              className="text-danger mt-2"
              style={{ display: formik.touched.videoThumbnail === true || imageError ? "block" : "none" }}
              role="alert"
            >
              <small>{formik.touched.videoThumbnail === true ? (imageError ? imageError : formik.errors.videoThumbnail || "") : ""}</small>
            </div>
            {/* <div className="d-flex align-item-center justify-content-center text-danger" style={{ display: imageError ? "block" : "none" }} role="alert">
                                                    <small>{imageError}</small>
                                                </div> */}
          </div>
          <div className="col-md-4 col-xs-12 pt-3 pl-4" style={{ display: "none" }}>
            {formik.values.videoSource === "Vimeo" && props.editDocumentData ? (
              <iframe
                src={`https://player.vimeo.com/video/${formik.values.videoId}`}
                width="100%"
                height="130"
                style={{ border: "none" }}
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
                title={`Video player for ${formik.values.videoId}`}
              ></iframe>
            ) : (
              ""
            )}
            {formik.values.videoSource === "YouTube" && props.editDocumentData ? (
              <iframe
                src={`https://www.youtube.com/embed/${formik.values.videoId}?rel=0&autoplay=0&showinfo=0`}
                width="100%"
                height="130"
                style={{ border: "none" }}
                allow="autoplay; encrypted-media"
                allowfullscreen
                title={`Video player for ${formik.values.videoId}`}
              ></iframe>
            ) : (
              ""
            )}
          </div>
        </div>
      </Modal.Body>
      <div className="modal-footer">
        <button type="button" onClick={handleReset} className="btn btn-outline-dark cy-btn mr-3">
          Cancel
        </button>
        <button type="button" onClick={handleSubmitDocument} className="btn btn-primary cy-btn">
          Save
        </button>
      </div>
    </Modal>
  );
};

export default VideoModal;
VideoModal.propTypes = {
  companyId: PropTypes.string,
  closeHandler: PropTypes.func,
  editDocumentData: PropTypes.bool,
  editVideoId: PropTypes.string,
  showModal: PropTypes.bool,
};
