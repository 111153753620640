/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Company Trend UI and API wiring up 
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 08/Aug/2020 
	Author						: Aditya Tijare
================================================================ 
*/

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getLoggedInUser, getProductVariant } from "../../../../../../utilities";
import TreeView from "../../../../../components/treeView/trend";
import { processingSubmit } from "../../../../../../middleware/services/cmsApi";
import { actionSuccess, hideLoader, actionError } from "../../../../../../middleware/actions/utilityAction";
import {
  fetchCompanyTrend,
  saveCompanyTrend,
  deleteCompanyTrend,
  deleteManyCompanyTrend,
  fetchSingleCompany,
  updateContentTags,
  saveCompanyTrendSequence,
} from "../../../../../../middleware/services/companyApi";
import { fetchProductBasic } from "../../../../../../middleware/services/productApi";
import { showAlertBox } from "../../../../../../middleware/actions/alertBoxAction";
import { Modal } from "react-bootstrap";
import { COMMON_ACCESS_ACTION } from "../../../../../../constants";
import PropTypes from "prop-types";

const TaxomonyUI = (props) => {
  const [clickSaveSubmit, setClickSaveSubmit] = useState(false);
  const [updateClick, setUpdateClick] = useState(false);

  const accessActionAdd = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD);
  const accessActionAddTags = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_TAGS);
  const accessActionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);
  const accessActionSubmit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.SUBMIT);
  const accessActionTaxonomyShowInProduct = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.SHOW_IN_PRODUCT);

  const history = useHistory();
  const dispatch = useDispatch();
  const loggedInUser = getLoggedInUser();
  const companyId = props.companyId;
  const [saveTreeData, setSaveTreeData] = useState(false);
  const [saveTreeDataAction, setSaveTreeDataAction] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [companyProducts, setCompanyProducts] = useState([]);
  const [showModalError, setShowModalError] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const cancelPath = props.path === "/processing/company/:companyId/edit" ? "/processing/company" : "/company";
  const handleSubmit = (selectedTech, checkValues, callback, sgfData = undefined, callback2) => {
    if (
      cancelPath === "/processing/company" ||
      props.path == "/processing/company/:companyId/edit" ||
      props.path == "/company/:companyId/edit" ||
      props.path == "/data-qc/company/:companyId/edit"
    ) {
      if (companyProducts?.length) {
        saveTrendProcessing(selectedTech);
      } else {
        saveTrend(selectedTech, checkValues, callback, sgfData, callback2);
      }
    }
  };

  const saveTrend = (selectedTech, taxonomyIds, callback, sgfData, callback2) => {
    if (selectedTech) {
      deleteCompanyTrend(companyId, selectedTech)
        .then(() => {
          if (taxonomyIds.length > 0) {
            try {
              const response = saveCompanyTrend(companyId, taxonomyIds, sgfData);
              response
                .then((_response) => {
                  if (submitStatus) {
                    setTimeout(() => {
                      saveTrendProcessing(selectedTech);
                    }, [2000]);
                  }
                  dispatch(actionSuccess("Trend Tagged Successfully"));
                  callback();
                })
                .catch((_e) => {
                  dispatch(actionError("Something Went Wrong...!"));
                  setSubmitStatus(false);
                });
            } catch (_e) {
              dispatch(hideLoader());
              dispatch(actionError("Something Went Wrong...!"));
              setSubmitStatus(false);
            }
          } else {
            dispatch(actionError("Please select taxonomy"));
            setSaveTreeData(false);
            setSaveTreeDataAction(false);
            setClickSaveSubmit(false);
            setSubmitStatus(false);
          }
        })
        .catch((_e) => {
          dispatch(hideLoader());
          dispatch(actionError("Something Went Wrong...!"));
          setSubmitStatus(false);
        });
    }
    setSaveTreeData(false);
    setSaveTreeDataAction(false);
  };

  const saveTrendProcessing = (selectedTech, taxonomyIds, callback) => {
    const payload = {
      userId: loggedInUser._id,
      trendId: selectedTech,
    };
    dispatch(
      showAlertBox({
        okCallback: async () => {
          processingSubmit(companyId, "trend", payload)
            .then((_response) => {
              setClickSaveSubmit(false);
              setUpdateClick(false);
              dispatch(actionSuccess(`Company trend successfully submitted`));
            })
            .catch((err) => {
              console.log(err?.data?.message);
              setSubmitStatus(false);
              // dispatch(actionError(err?.data?.message || "Something went wrong"))
              setShowModalError(true);
              setShowMessage(err?.data?.message || "Something went wrong");
            });
        },
        cancelCallback: () => {
          setClickSaveSubmit(false);
          setUpdateClick(false);
        },
        okText: "Submit",
        cancelText: "Cancel",
        content: "Are you sure you want to submit ?",
        title: "dialogAlertCss",
      })
    );
    setClickSaveSubmit(false);
    setUpdateClick(false);
    setSaveTreeData(false);
    setSaveTreeDataAction(false);
    setSubmitStatus(false);
  };
  const fetchProductBasicWithCompany = () => {
    let defaultFilter = [["productCompany.companyId", "eq", props.companyId]];
    const productVariant = getProductVariant();
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    let params = {};
    params.fields = ["productCompany", "productName"];
    params.filters = [...defaultFilter];
    fetchProductBasic({ ...params })
      .then((res) => {
        const { data = [] } = res.data;
        setCompanyProducts([...data]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteTrend = (checkedTrends, callback) => {
    deleteManyCompanyTrend(companyId, checkedTrends)
      .then((res) => {
        callback();
        // callback2(selectedTech)
        dispatch(actionSuccess("Taxonomy deleted Successfully."));
      })
      .catch((err) => {
        dispatch(actionError("Something Went Wrong...!"));
      });
  };

  const handleSaveTagSequence = (sortedTrends, callback) => {
    saveCompanyTrendSequence(companyId, sortedTrends)
      .then((res) => {
        callback();
        dispatch(actionSuccess("Taxonomy sequence added Successfully."));
      })
      .catch((err) => {
        dispatch(actionError("Something Went Wrong...!"));
      });
  };

  useEffect(() => {
    fetchProductBasicWithCompany();
  }, []);

  return (
    <>
      <TreeView
        handleSave={saveTrend}
        saveTreeData={saveTreeData}
        contentId={companyId}
        fetchContentTagging={fetchCompanyTrend}
        loadOtherTagInfo={props.loadOtherTagInfo}
        deleteTrend={deleteTrend}
        isDelete={companyProducts?.length ? false : true}
        setSaveTreeData={setSaveTreeData}
        accessPermissionTaxonomyDelete={accessActionDelete}
        accessPermissionTaxonomy={accessActionAdd}
        accessPermissionAddTags={accessActionAddTags}
        accessActionTaxonomyShowInProduct={accessActionTaxonomyShowInProduct}
        handleSubmit={handleSubmit}
        submitStatus={submitStatus}
        fetchTags={fetchSingleCompany}
        saveTags={updateContentTags}
        contentModule={"Company"}
        handleSaveTagSequence={handleSaveTagSequence}
      />
      <div className="mx-n4">
        <div className="form-submit-box pr-0">
          <div className="container-fluid" style={{ paddingRight: "6px" }}>
            <div className="d-flex justify-content-between">
              <div>
                {props.selectedIndex > 0 || props.selectedIndex === undefined ? (
                  <span className="" style={{ paddingLeft: "-0.5em" }}>
                    <span className="span-link">
                      <button
                        type="button"
                        className="btn btn-outline-dark pl-4 pr-4 cy-btn"
                        onClick={() => {
                          (props.selectedIndex === undefined && props.setCurrentTab(3)) ||
                            (props.selectedIndex < 5 && props.setCurrentTab(props.selectedIndex - 1));
                        }}
                      >
                        Previous
                      </button>
                    </span>
                  </span>
                ) : null}
                {props.selectedIndex > -1 ? (
                  <span className="pl-4">
                    <span className="span-link">
                      <button
                        type="button"
                        className="cy-col-btn btn btn-outline-dark pl-4 pr-4 cy-btn"
                        onClick={() => {
                          props.selectedIndex < 5 && props.setCurrentTab(props.selectedIndex + 1);
                        }}
                      >
                        Next
                      </button>
                    </span>
                  </span>
                ) : null}
              </div>
              <div>
                <button type="button" className="btn btn-outline-dark cy-btn pl-3 pr-3 mr-3" onClick={() => history.push(`${cancelPath}`)}>
                  Cancel
                </button>
                {accessActionSubmit ? (
                  <>
                    {cancelPath === "/processing/company" ? (
                      <button
                        type="button"
                        className="btn btn-primary cy-btn pl-2 pr-2"
                        onClick={() => {
                          if (companyProducts.length < 1) {
                            if (clickSaveSubmit) setClickSaveSubmit(false);
                            setClickSaveSubmit(true);
                          } else {
                            setSaveTreeDataAction("saveAndSubmit");
                          }
                        }}
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary cy-btn pl-2 pr-2"
                        onClick={() => {
                          setSubmitStatus(true);
                        }}
                      >
                        {" "}
                        Submit
                      </button>
                    )}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModalError} backdrop="static" className="modal fade lg" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-3" style={{ marginLeft: "24px !important" }}>
              {showMessage}
            </div>
          </div>
        </Modal.Body>
        <div className="modal-footer">
          <button type="button" className="btn btn-outline-dark cy-btn" onClick={() => setShowModalError(!showModalError)}>
            Ok
          </button>
        </div>
      </Modal>
    </>
  );
};
TaxomonyUI.propTypes = {
  interfaceActionAccess: PropTypes.bool,
  companyId: PropTypes.string,
  path: PropTypes.string,
  loadOtherTagInfo: PropTypes.func,
  selectedIndex: PropTypes.number,
  setCurrentTab: PropTypes.func,
};
export default TaxomonyUI;
