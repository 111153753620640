/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  Deliverable Listing Page
   - Use common grid component for list
 ---------------------------------------------------------------------------------
  Creation Details
  Date Created				: 20/Aug/2020
  Author						: Aditya Tijare
================================================================
*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getProductVariant, fetchTechTrendData, getLoggedInUser, decodeHTML, getDateFromMongoDate } from "../../../../utilities";
import { actionSuccess, actionError, showLoader, hideLoader } from "../../../../middleware/actions/utilityAction";
import CommonDataGrid from "../../../components/dataGrid";
import { StringFilter, DropDownFilter } from "../../../components/dataGrid/fliters";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import {
  fetchDeliverables,
  deleteDeliverable,
  saveDeliverableTaxonomyTags,
  saveDeliverableKiaTags,
  saveDeliverableTrendTags,
  saveDeliverableValueChainTags,
  saveDeliverableBusinessObjectiveTaxonomy,
} from "../../../../middleware/services/deliverablesApi";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import ThreeDotMenu from "../../../components/threeDotMenu";
import { PRODUCT_VARIANT, COMMON_ACCESS_ACTION, CONTENT_TYPE, DELIVERABLE_TYPE } from "../../../../constants";
import { visitRecord, setMaintainedState } from "../../../../middleware/actions/gridAction";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { resetRedux, setFilteredTaxonomy } from "../../../../middleware/actions/taxonomyFilterAction";
import {
  setInitialCheckedKiaNodes,
  setInitialCheckedObjectiveNodes,
  setInitialCheckedSgfNodes,
  setInitialCheckedTechnologyNodes,
  setInitialCheckedTrendNodes,
  setInitialCheckedValueChainNodes,
  setKiaCheckedNodes,
  setKiaUnCheckedNodes,
  setObjectiveTaxonomyCheckedNodes,
  setObjectiveTaxonomyUnCheckedNodes,
  setResetRedux,
  setTaxonomyCheckedNodes,
  setTaxonomyUnCheckedNodes,
  setTransformingCheckedNodes,
  setTransformingUnCheckedNodes,
  setTrendCheckedNodes,
  setTrendUnCheckedNodes,
  setValueChainCheckedNodes,
  setValueChainUnCheckedNodes,
} from "../../../../middleware/actions/taxonomyTaggingAction";
import _ from "lodash";
import { setDeliverableSettingDetails } from "../../../../middleware/actions/deliverableAction";
import { getContentType } from "../../../../middleware/services/cmsApi";

export default (props) => {
  const [deleteId, setDeleteId] = useState(undefined);
  const gridState = useSelector((state) => state.gridState);
  const { accessRights: aR } = props;
  const [initalState, setInitialState] = useState([]);
  const [bulkCheck, setBulkCheck] = useState([]);
  const [valueChainData, setValueChainData] = useState([]);
  const [showTaxonomyBulk, setShowTaxonomyBulk] = useState(false);
  const [cssClass, setCssClass] = useState("treeClass");
  const [listClass, setListClass] = useState("select-lists");
  const deliverableTaxonomy = useSelector((state) => state.taxonomyTaggingState);
  const [taxonomyTaggingTabName, setTaxonomyTaggingTabName] = useState(false);
  const [taxonomyState, setTaxonomyState] = useState([]);
  const [taxonomyTransformationState, setTaxonomyTransformationState] = useState([]);
  const [roleValue, setRoleValue] = useState("all");
  const [deliverableType, setDeliverableType] = useState(undefined);
  const [deliverableContentType, setDeliverableContentType] = useState(undefined);
  const reportContentType = [CONTENT_TYPE.SIGNALS, CONTENT_TYPE.DASHBOARD];

  /* Common access */
  const accessActionEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT);
  const accessActionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);
  const accessActionPreview = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.PREVIEW);
  const accessActionListingAddNew = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_NEW);
  const accessActionFilterByTaxonomy = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.FILTER_BY_TAXONOMY);
  const accessActionTaxonomyBulkTagging = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.TAXONOMY_BULK_TAGGING);
  const accessAdministrator = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.REPORT_ADMINISTRATOR);

  const deliverableTypes = [
    {
      deliverableTypeName: "Trend Deep Dive",
      deliverableTypeValue: "Trend Deep Dive",
      productVariant: PRODUCT_VARIANT.INSIDER,
    },
    {
      deliverableTypeName: "Technology Deep Dive",
      deliverableTypeValue: "Technology Deep Dive",
      productVariant: PRODUCT_VARIANT.INSIDER,
    },
    {
      deliverableTypeName: "Executive Lens",
      deliverableTypeValue: "Executive Lens",
      productVariant: PRODUCT_VARIANT.INSIDER,
    },
    {
      deliverableTypeName: "Technology & Solutions",
      deliverableTypeValue: "Technology & Solutions",
      productVariant: PRODUCT_VARIANT.INSIDER,
    },
    {
      deliverableTypeName: "Benchmarking",
      deliverableTypeValue: "Benchmarking",
      productVariant: PRODUCT_VARIANT.INSIDER,
    },
    {
      deliverableTypeName: "Bulletin",
      deliverableTypeValue: "Bulletin",
      productVariant: PRODUCT_VARIANT.INSIDER,
    },
    {
      deliverableTypeName: "Pulse",
      deliverableTypeValue: "Pulse",
      productVariant: PRODUCT_VARIANT.INSIDER,
    },
    {
      deliverableTypeName: "Patent Watch",
      deliverableTypeValue: "Patent Watch",
      productVariant: PRODUCT_VARIANT.INSIDER,
    },
    {
      deliverableTypeName: "Research Watch",
      deliverableTypeValue: "Research Watch",
      productVariant: PRODUCT_VARIANT.INSIDER,
    },
    {
      deliverableTypeName: DELIVERABLE_TYPE.CLIENT_BUSINESS_OBJECTIVE,
      deliverableTypeValue: DELIVERABLE_TYPE.CLIENT_BUSINESS_OBJECTIVE,
      productVariant: PRODUCT_VARIANT.INSIDER,
    },
    {
      deliverableTypeName: "Trend Overview",
      deliverableTypeValue: "Trend Overview",
      productVariant: PRODUCT_VARIANT.INSIDER,
    },
    {
      deliverableTypeName: "WhatNext Perspective",
      deliverableTypeValue: "WhatNext Perspective",
      productVariant: PRODUCT_VARIANT.WHATNEXT,
    },
    {
      deliverableTypeName: DELIVERABLE_TYPE.CLIENT_BUSINESS_OBJECTIVE,
      deliverableTypeValue: DELIVERABLE_TYPE.CLIENT_BUSINESS_OBJECTIVE,
      productVariant: PRODUCT_VARIANT.WHATNEXT,
    },
    {
      deliverableTypeName: "Quarterly Report",
      deliverableTypeValue: "Quarterly Report",
      productVariant: PRODUCT_VARIANT.WHATNEXT,
    },
  ];

  const contentTypes = [
    {
      deliverableContentTypeName: CONTENT_TYPE.INSIGHTS_REPORTS_RESEARCH,
      deliverableContentTypeValue: CONTENT_TYPE.INSIGHTS_REPORTS_RESEARCH,
      productVariant: PRODUCT_VARIANT.INSIDER,
    },
    {
      deliverableContentTypeName: CONTENT_TYPE.WEBINARS_INTERVIEWS_PODCASTS,
      deliverableContentTypeValue: CONTENT_TYPE.WEBINARS_INTERVIEWS_PODCASTS,
      productVariant: PRODUCT_VARIANT.INSIDER,
    },
    {
      deliverableContentTypeName: CONTENT_TYPE.INSIGHTS_REPORTS_RESEARCH,
      deliverableContentTypeValue: CONTENT_TYPE.INSIGHTS_REPORTS_RESEARCH,
      productVariant: PRODUCT_VARIANT.INSIDER,
    },
    {
      deliverableContentTypeName: "Dashboard",
      deliverableContentTypeValue: CONTENT_TYPE.DASHBOARD,
      productVariant: PRODUCT_VARIANT.INSIDER,
    },
  ];

  const productVariant = getProductVariant();
  const dispatch = useDispatch();
  const history = useHistory();
  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());
  const [techTrendData, setTechTrendData] = useState(undefined);
  let user = getLoggedInUser();
  const moduleName = props.accessRights?.moduleName;
  const taxonomyFilterRedux = useSelector((state) => state.taxonomyFilterState);
  const [filterData, setFilterData] = useState(taxonomyFilterRedux?.filteredTaxonomy ? taxonomyFilterRedux.filteredTaxonomy : []);
  /*
    @Description : List of deliverables columns
  */
  const deliverableCol = {
    id: "id",
    "Deliverable Name": "deliverableName",
    "Content Type": "deliverableContentType",
    // "Technology": "deliverableTechnologyTags~dtParentId:0,dtName",
    // "Trend": "deliverableIndustryTrendsTags.trendName",
    "Deliverable Type": "deliverableType",
    publishDate: "deliverablePublishedDate",
    "Create Date": "deliverableCreatedDate",
    "Is Live": "deliverableIsLive",
    "Est. Start Date": "deliverableEstStartDate",
    "Est. End Date": "deliverableEstEndDate",
    Status: "deliverableContentStatus",
    Writer: "deliverableTaskWriters.user_name",
    Publisher: "deliverableTaskPublishers.user_name",
    Reviewer: "deliverableTaskReviewers.user_name",
  };
  if (productVariant) deliverableCol["Is Live"] = `productVariant~productName:${productVariant},isLive`;
  else deliverableCol["Is Live"] = "productVariant.isLive";

  if (productVariant && productVariant === PRODUCT_VARIANT.INSIDER) deliverableCol["Trend"] = "deliverableIndustryTrendsTags.trendId";
  else deliverableCol["Technology"] = "deliverableTechnologyTags.dtId";

  if (productVariant && productVariant === PRODUCT_VARIANT.INSIDER) {
    deliverableCol["Most Read"] = "deliverableIsMostResearch";
    deliverableCol["Popular"] = "deliverableIsPopularReport";
    deliverableCol["Coming Soon"] = "deliverableIsComingSoon";
  }

  const breadCrumbLinks = [{ linkUrl: "/deliverable", linkName: aR.moduleName, linkActive: true }];

  // Reset Redux
  useEffect(() => {
    const locationPath = localStorage.getItem("locationPath") ? localStorage.getItem("locationPath") : [];
    if (locationPath != `/deliverable`) {
      setFilterData([]);
      dispatch(setFilteredTaxonomy([]));
      dispatch(resetRedux());
      setFilterData([]);
      localStorage.setItem("locationPath", window.location.pathname);
    }
    dispatch(setDeliverableSettingDetails(""));
  }, []);
  const fetchTechData = async () => {
    setTechTrendData(await fetchTechTrendData(productVariant));
  };
  const deliverableStatusFilter = (value) => {
    let sourceData = [
      { id: "In Progress", text: "In Progress" },
      { id: "Submitted", text: "Submitted" },
      { id: "Rejected", text: "Rejected" },
      { id: "Published", text: "Published" },
      { id: "Approved", text: "Approved" },
    ];
    return <DropDownFilter value={value} sourceData={sourceData} />;
  };
  /*
    @Description : Use for setting breadcrumb values
  */

  useEffect(() => {
    localStorage.removeItem("selectedDeliverable");
    if (!techTrendData) fetchTechData();
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => {
      dispatch(setBreadCrumb());
      localStorage.removeItem("selectedDeliverable");
    };
  }, [dispatch]);

  /* 
    @Description : Template Component for action column
  */
  const actionTemplate = (value) => {
    return (
      <ThreeDotMenu
        rowID={value.id}
        {...(accessActionEdit
          ? {
              Edit: () => {
                history.push(`/deliverable/${value.id}/edit#basic`, {
                  deliverableId: value.id,
                  deliverableContentStatus: value["deliverableContentStatus"],
                });
                dispatch(visitRecord());
              },
            }
          : {})}
        {...(accessActionDelete
          ? {
              Delete: async () => {
                value.Status !== "Published"
                  ? dispatch(
                      showAlertBox({
                        okCallback: async () => {
                          deleteDeliverable(value.id)
                            .then((response) => {
                              dispatch(actionSuccess("Deliverable deleted successfully"));
                              setDeleteId(value.id);
                            })
                            .catch((err) => {
                              dispatch(actionError(err.data?.message || "Fail to delete deliverable"));
                            });
                        },
                        okText: "Delete",
                        cancelText: "Cancel",
                        content: "Are you sure you want to delete ?",
                        title: "dialogAlertCssWarning",
                      })
                    )
                  : dispatch(actionError("Published deliverable cannot be deleted."));
              },
            }
          : {})}
        {...(value.isDocFileUploaded === 0 &&
          !reportContentType.includes(value["Content Type"]) &&
          accessActionPreview && {
            Preview: () => {
              handlePreview(value.id);
            },
          })}
      />
    );
  };
  /*
    @Description : Template Component for Deliverable Name
  */
  /*
    @Description : Template Component for Date col
  */
  const deliverableDateTemplate = (value) => {
    switch (value.column.field) {
      case "Est. Start Date":
        return value["Est. Start Date"] instanceof Date && !isNaN(Date.parse(value["Est. Start Date"])) ? (
          <span>{getDateFromMongoDate(value["Est. Start Date"])}</span>
        ) : (
          ""
        );
      case "Est. End Date":
        return value["Est. End Date"] instanceof Date && !isNaN(Date.parse(value["Est. End Date"])) ? (
          <span>{getDateFromMongoDate(value["Est. End Date"])}</span>
        ) : (
          ""
        );
      case "publishDate":
        return value["publishDate"] instanceof Date && !isNaN(Date.parse(value["publishDate"])) ? (
          <span>{getDateFromMongoDate(value["publishDate"])}</span>
        ) : (
          ""
        );
      default:
        return "";
    }
  };
  const truncateStyle = {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontSize: "14px",
  };

  const truncateStyleDeliverableName = {
    textOverflow: "ellipsis",
    whiteSpace: "break-word",
    fontSize: "14px",
  };

  const renderToolTip = (value, id) => {
    if (value.length >= 15) {
      return (
        <TooltipComponent position="BottomCenter" content={value}>
          <div id={id} style={truncateStyle}>
            {value.slice(0, 15) + "..."}
          </div>
        </TooltipComponent>
      );
    } else {
      return (
        <div id={id} style={truncateStyle}>
          {value}
        </div>
      );
    }
  };
  //show tooltip when characters is more than 100
  const renderToolTip100Chars = (value, id) => {
    let colDescValue = value;
    let desc = decodeHTML(colDescValue || "").replace(/(<([^>]+)>)/gi, "");
    if (value.length >= 100) {
      return (
        <TooltipComponent position="BottomCenter" content={value}>
          <div id={id} style={{ fontSize: "14px" }}>
            {desc.length > 100 ? desc.substring(0, 100).concat("...") : desc}
          </div>
        </TooltipComponent>
      );
    } else {
      return (
        <div id={id} style={truncateStyleDeliverableName}>
          {value}
        </div>
      );
    }
  };

  const deliverableNameTemplate = (value) => {
    return (
      <div
        className="span-link"
        onClick={() => {
          let element = Array.from(document.getElementsByClassName("e-tooltip-wrap e-popup e-lib e-control e-popup-open"));
          element.forEach((box) => {
            box.remove();
          });
          history.push(`/deliverable/${value.id}/edit#basic`, {
            deliverableId: value.id,
            deliverableContentStatus: value["deliverableContentStatus"],
          });
          dispatch(visitRecord());
        }}
      >
        {renderToolTip100Chars(value["Deliverable Name"], "deliverableName")}
      </div>
    );
  };

  const trendTemplate = (value) => {
    return <div className="span-link">{renderToolTip100Chars(value["Trend"], "trend")}</div>;
  };

  const technologyTemplate = (value) => {
    return <div className="span-link">{renderToolTip100Chars(value["Technology"], "technology")}</div>;
  };

  const deliverableWriterTemplate = (value) => {
    return <div>{!value.Writer ? "" : renderToolTip(value.Writer, "deliverableWriter")}</div>;
  };
  const deliverableReviewerTemplate = (value) => {
    return <div>{!value.Reviewer ? "" : renderToolTip(value.Reviewer, "deliverableReviewer")}</div>;
  };
  const deliverablePublisherTemplate = (value) => {
    return <div>{!value.Publisher ? "" : renderToolTip(value.Publisher, "deliverablePublisher")}</div>;
  };

  // Dropdown Filter template
  const ItemFilter = (value) => {
    let sourceData = [];
    switch (value.column.field) {
      case "Is Live":
        sourceData = [
          { id: "YES", text: "YES" },
          { id: "NO", text: "NO" },
        ];
        break;
      case "Technology":
        sourceData = techTrendData;
        break;
      case "Trend":
        sourceData = techTrendData;
        break;
      case "Status":
        sourceData = [
          { id: "In Progress", text: "In Progress" },
          { id: "Submitted", text: "Submitted" },
          { id: "Rejected", text: "Rejected" },
          { id: "Published", text: "Published" },
          { id: "Approved", text: "Approved" },
        ];
        break;

      case "Content Type":
        sourceData = deliverableContentType;
        break;
      case "Deliverable Type":
        sourceData = deliverableType;
        break;
      case "Most Read":
        sourceData = [
          { id: "YES", text: "YES" },
          { id: "NO", text: "NO" },
        ];
        break;
      case "Popular":
        sourceData = [
          { id: "YES", text: "YES" },
          { id: "NO", text: "NO" },
        ];
        break;
      default:
        break;
    }

    return <DropDownFilter value={value} sourceData={sourceData} />;
  };

  /*
    @Description : Sending CcolumnFields to Grid
  */
  const columnFields = [
    {
      field: "Deliverable Name",
      type: "String",
      template: deliverableNameTemplate,
      filterTemplate: StringFilter,
      showInColumnChooser: false,
      textAlign: "Left",
      width: 400,
    },
    {
      field: `${productVariant && productVariant === PRODUCT_VARIANT.INSIDER ? "Trend" : "Technology"}`,
      template: productVariant && productVariant === PRODUCT_VARIANT.INSIDER ? trendTemplate : technologyTemplate,
      type: "String",
      filterTemplate: ItemFilter,
      filter: { operator: "contains" },
      textAlign: "Left",
      width: 350,
    },

    productVariant &&
      productVariant !== PRODUCT_VARIANT.WHATNEXT && {
        field: "Content Type",
        type: "String",
        textAlign: "Left",
        filterTemplate: ItemFilter,
        width: 220,
      },

    // { field: "Trend", type: "String", filterTemplate: StringFilter, textAlign: "Left", headerTextAlign: "Center", },
    {
      field: "Deliverable Type",
      type: "String",
      textAlign: "Left",
      filterTemplate: ItemFilter,
      width: 220,
    },
    {
      field: "Writer",
      type: "String",
      textAlign: "Left",
      width: 220,
      filterTemplate: StringFilter,
      template: deliverableWriterTemplate,
    },
    {
      field: "Reviewer",
      type: "String",
      textAlign: "Left",
      width: 220,
      filterTemplate: StringFilter,
      template: deliverableReviewerTemplate,
    },
    {
      field: "Publisher",
      type: "String",
      textAlign: "Left",
      width: 220,
      filterTemplate: StringFilter,
      template: deliverablePublisherTemplate,
    },
    {
      field: "Status",
      type: "String",
      textAlign: "Center",
      width: 120,
      filterTemplate: deliverableStatusFilter,
    },
    {
      field: "Est. Start Date",
      type: "Date",
      textAlign: "Center",
      template: deliverableDateTemplate,
      width: 160,
    },
    {
      field: "publishDate",
      headerText: "Published Date",
      type: "Date",
      template: deliverableDateTemplate,
      textAlign: "Center",
      width: 190,
      headerTextAlign: "Center",
      useRange: true,
    },
    {
      field: "Est. End Date",
      type: "Date",
      textAlign: "Center",
      template: deliverableDateTemplate,
      width: 160,
    },
    {
      field: "Is Live",
      type: "String",
      filterTemplate: ItemFilter,
      filter: { operator: "equal" },
      textAlign: "Center",
      width: 120,
    },
    productVariant &&
      productVariant === PRODUCT_VARIANT.INSIDER && {
        field: "Most Read",
        type: "String",
        filterTemplate: ItemFilter,
        filter: { operator: "equal" },
        textAlign: "Center",
        width: 150,
      },
    productVariant &&
      productVariant === PRODUCT_VARIANT.INSIDER && {
        field: "Popular",
        type: "String",
        filterTemplate: ItemFilter,
        filter: { operator: "equal" },
        textAlign: "Center",
        width: 130,
      },
  ];
  /*
    @Description : Check permission for access column
  */
  if (accessActionEdit || accessActionDelete || accessActionPreview) {
    columnFields.unshift({
      field: null,
      type: "checkbox",
      width: 32,
      allowFiltering: false,
      showInColumnChooser: false,
      textAlign: "Center",
    });
    columnFields.push({
      field: "Action",
      type: "String",
      width: 65,
      template: actionTemplate,
      allowFiltering: false,
      showInColumnChooser: false,
      textAlign: "Right",
      headerTextAlign: "Center",
      allowSorting: false,
      freeze: "Right",
    });
  }

  // formatRowData function required to format rowData for datGrid
  const formatRowData = (rowData) => {
    let formatedRowData = [];
    rowData.forEach((deliverableData) => {
      let data = {};
      data.id = deliverableData.id;
      data["Deliverable Name"] = deliverableData.deliverableName;
      data["Writer"] = deliverableData?.deliverableTaskWriters?.length
        ? deliverableData?.deliverableTaskWriters
            .map((writer) => {
              return `${writer.userName}`;
            })
            .join(", ")
        : "";
      data["Reviewer"] = deliverableData?.deliverableTaskReviewers?.length
        ? deliverableData?.deliverableTaskReviewers
            .map((reviewer) => {
              return `${reviewer.userName}`;
            })
            .join(", ")
        : "";
      data["Publisher"] = deliverableData?.deliverableTaskPublishers?.length
        ? deliverableData?.deliverableTaskPublishers
            .map((publisher) => {
              return `${publisher.userName}`;
            })
            .join(", ")
        : "";
      if (productVariant && productVariant === PRODUCT_VARIANT.INSIDER) {
        data.Trend = deliverableData.deliverableIndustryTrendsTags
          .map((trend) => {
            if (trend.trendParentId != 0 && trend.trendRoot != 0 && trend.trendSubRoot == 0 && !trend.trendIsSgf) {
              return trend.trendName;
            }
            return "";
          })
          .filter((trend) => trend != "")
          .join(", ");
      } else {
        data.Technology = deliverableData.deliverableTechnologyTags
          .map((tech) => {
            if (tech.dtParentId == 0 && tech.dtRoot == 0) {
              return tech.dtName;
            }
            return "";
          })
          .filter((tech) => tech != "")
          .join(", ");
      }
      data["Est. Start Date"] = new Date(deliverableData.deliverableEstStartDate);
      data["Est. End Date"] = new Date(deliverableData.deliverableEstEndDate);
      if (deliverableData.productVariant?.length) {
        if (productVariant) {
          const rDPV = deliverableData.productVariant.find((pV) => pV.productName === productVariant);
          data["Is Live"] = rDPV?.isLive ? rDPV.isLive : "NO";
        } else data["Is Live"] = deliverableData.productVariant[0].isLive ? deliverableData.productVariant[0].isLive : "NO";
      } else data["Is Live"] = "NO";
      if (productVariant && productVariant !== PRODUCT_VARIANT.WHATNEXT) {
        data["Content Type"] = deliverableData.deliverableContentType;
      }
      data["Deliverable Type"] = deliverableData.deliverableType;
      data["Status"] = deliverableData.deliverableContentStatus;

      data.isDocFileUploaded = deliverableData.deliverableDocuments ? 1 : 0;
      // data["Deliverable Block Status"] = deliverableData.deliverableContentStatus
      if (productVariant && productVariant === PRODUCT_VARIANT.INSIDER) {
        data["Most Read"] = deliverableData?.deliverableIsMostResearch ? deliverableData.deliverableIsMostResearch : "NO";
        data["Popular"] = deliverableData?.deliverableIsPopularReport ? deliverableData.deliverableIsPopularReport : "NO";
        data["Coming Soon"] = deliverableData?.deliverableIsComingSoon ? deliverableData.deliverableIsComingSoon : "NO";
      }
      data["publishDate"] = (deliverableData.deliverablePublishedDate && new Date(deliverableData.deliverablePublishedDate)) || "";
      formatedRowData.push(data);
    });
    return formatedRowData;
  };

  const fetchDeliverableWithProductVariant = async (params = {}) => {
    let defaultFilter = [];
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    let userCompetencyIndustry = JSON.parse(localStorage.getItem("userCompetencyIndustry")) || [];
    let { filters = [] } = params;
    if (!accessAdministrator) defaultFilter.push(["taskUserId", "eq", user._id]);
    let trendFiltering = false;
    if (filters) {
      filters = filters.map((item) => {
        /**True-false is trend filter is selected */
        trendFiltering = item.includes("deliverableIndustryTrendsTags.trendId");
        /** */
        const tech = techTrendData.filter((f) => {
          return ["deliverableIndustryTrendsTags.trendId", "deliverableTechnologyTags.dtId"].includes(item[0]) && f.text === item[2];
        });
        if (tech.length > 0) {
          if (productVariant === PRODUCT_VARIANT.INSIDER) {
            return item[0] === "deliverableIndustryTrendsTags.trendId" ? [item[0], "in", [tech[0].id]] : item;
          } else {
            return item[0] === "deliverableTechnologyTags.dtId" ? [item[0], "in", [tech[0].id]] : item;
          }
        } else return item;
      });
    }

    if (!trendFiltering && userCompetencyIndustry?.length > 0) {
      const industryId = userCompetencyIndustry?.map((obj) => obj.id);
      productVariant === PRODUCT_VARIANT.INSIDER && defaultFilter.push(["deliverableIndustryTrendsTags.trendId", "in", industryId]);
    }
    params.filters = [...defaultFilter, ...filters];

    if (!params.sort) {
      params.sort = "deliverableCreatedDate:desc";
    }
    if (!gridState.recordVisited) {
      if (!params.skip) {
        params.skip = 0;
      }
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (gridState.recordVisited) {
      params = {
        filters: gridState.filtersParams,
        sort: gridState.sortRecords,
        skip: gridState.skipRecords,
        limit: gridState.recordsPerPage,
      };
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (params.filters?.length === 0) {
      params.filters = [...filters, ...defaultFilter];
      return fetchDeliverables(params);
    }

    if (Object.keys(filterData).length > 0) {
      let technologyFilter = [];
      let kiaFilter = [];
      let valueChainFilter = [];
      let businessObjective = [];
      technologyFilter = filterData?.technology?.length > 0 ? ["deliverableTechnologyTags.dtId", "in", filterData.technology] : [];
      kiaFilter = filterData?.kia?.length > 0 ? ["deliverableKiaTags.kiaId", "in", filterData.kia] : [];
      valueChainFilter = filterData?.valueChain?.length > 0 ? ["deliverableIndustryValuechainTags.valueChainId", "in", filterData.valueChain] : [];
      let boTaxonomy = filterData?.boTaxonomy?.length > 0 ? ["deliverableObjectiveTaxonomy.taxonomyId", "in", filterData.boTaxonomy] : [];
      businessObjective =
        filterData?.businessObjective?.length > 0 ? ["deliverableBusinessObjective.objectiveId", "in", filterData.businessObjective] : [];

      let trendfilter = filterData?.trend?.length > 0 ? ["deliverableIndustryTrendsTags.trendId", "in", filterData.trend] : [];

      params.filters = [...filters, ...defaultFilter];
      technologyFilter.length > 0 && params.filters.push(technologyFilter);
      kiaFilter.length > 0 && params.filters.push(kiaFilter);
      valueChainFilter.length > 0 && params.filters.push(valueChainFilter);
      businessObjective.length > 0 && params.filters.push(businessObjective);
      boTaxonomy.length > 0 && params.filters.push(boTaxonomy);
      trendfilter.length > 0 && params.filters.push(trendfilter);
    }
    if (roleValue !== "all") {
      let filteredRole = [roleValue + ".user_id", "eq", user._id];
      params.filters = [...params.filters, ...[filteredRole]];
    }
    return fetchDeliverables(params);
  };

  const objectiveTaxonomyTag = async () => {
    dispatch(visitRecord());
    const selectedDeliverable = localStorage.getItem("selectedDeliverable") ? JSON.parse(localStorage.getItem("selectedDeliverable")) : [];
    if (selectedDeliverable.length) {
      const params = {};
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedDeliverable],
      ];
      params.fields = ["productVariant"];
      dispatch(showLoader());
      const deliverableIsLive = await fetchDeliverables(params);
      dispatch(hideLoader());
      if (deliverableIsLive.data) {
        let nonLiveComp = deliverableIsLive.data.data.filter((comp) => {
          let isLIveComp = true;
          comp.productVariant.forEach((prod) => {
            if (prod.productName == productVariant && prod.isLive == "YES") {
              isLIveComp = false;
            }
          });
          return isLIveComp;
        });
        if (nonLiveComp.length) {
          dispatch(actionError("Please select only live deliverable."));
        } else {
          history.push(`/business-objective/taxonomy/deliverable`, {
            deliverableIds: selectedDeliverable,
            entityName: "deliverable",
          });
        }
      }
    } else {
      dispatch(actionError("Please select deliverable."));
    }
  };

  const handleSubmitTechnology = async (fetchGridData) => {
    let taxonomyTaggingPayload = {
      deliverableIds: initalState,
      taxonomyTagging: deliverableTaxonomy.taxonomyCheckedNodes,
      taxonomyUnTagging: deliverableTaxonomy.taxonomyUnCheckedNodes,
    };

    if (deliverableTaxonomy?.initialTechnologyCheckedNodes.length == 0) {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              setTimeout(() => {
                dispatch(hideLoader());
              }, 0);
            },
            cancelCallback: async () => {
              setShowTaxonomyBulk(false);
            },

            okText: "Ok",
            cancelText: "Cancel",
            content: "There is no change observed in taxonomy tagging, press Ok to continue or press Cancel to close.",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    } else {
      dispatch(
        showAlertBox({
          okCallback: async () => {
            setTimeout(() => {
              dispatch(showLoader());
              saveDeliverableTaxonomyTags(taxonomyTaggingPayload)
                .then((result) => {
                  dispatch(actionSuccess("Taxonomy updated successfully"));
                  dispatch(setResetRedux());
                  setShowTaxonomyBulk(false);
                  dispatch(hideLoader());
                  fetchGridData();
                })
                .catch((err) => {
                  dispatch(hideLoader());
                  dispatch(actionError("Please select deliverable"));
                });
            }, 0);
          },
          okText: "Submit",
          cancelText: "Cancel",
          content: "Are you sure you want to update the tagging?",
          title: "dialogAlertCssWarning",
        })
      );
    }
  };
  // Get call for bulk tagging
  const fetchTechnologyTagging = () => {
    setListClass("select-lists bulktagging");
    setCssClass("treeClass");
    const selectedDeliverable = localStorage.getItem("selectedDeliverable") ? JSON.parse(localStorage.getItem("selectedDeliverable")) : [];

    if (selectedDeliverable.length) {
      const params = { limit: gridState.recordsPerPage };
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedDeliverable],
      ];
      params.fields = ["deliverableTechnologyTags"];
      const contentTaxonomy = fetchDeliverables(params);
      contentTaxonomy
        .then((res) => {
          let foo = [];
          let count;
          res.data.data.map((value) => {
            value.deliverableTechnologyTags.length > 0 &&
              value.deliverableTechnologyTags.map((v) => {
                foo.push(v.dtId);
                count = {};
                for (const element of foo) {
                  if (count[element]) {
                    count[element] += 1;
                  } else {
                    count[element] = 1;
                  }
                }
              });

            let checked = count && Object.entries(count);
            let commonIds = [];
            checked &&
              checked.map((d) => {
                if (selectedDeliverable.length == d[1]) {
                  commonIds.push(d[0]);
                }
              });
            setBulkCheck([...deliverableTaxonomy.taxonomyCheckedNodes, ...commonIds]);
            setTaxonomyState([...commonIds]);
            dispatch(setTaxonomyCheckedNodes([...deliverableTaxonomy.taxonomyCheckedNodes, ...commonIds]));
          });
        })
        .catch((err) => {
          console.log("err fetchProductTaxonomy :: ", err);
        });
    }
  };

  const fetchValuchainData = (data, selectedDeliverable) => {
    if (data.length) {
      let valueChainIds = [];
      let commanIds = [];
      let taxonomyTee = [];
      data.map((value) => {
        value?.deliverableIndustryTag?.length > 0 &&
          value.deliverableIndustryTag.map((item) => {
            if (!valueChainIds.includes(item.industryId)) {
              let taxonomyTeeData = {};
              taxonomyTeeData["id"] = item.industryId;
              taxonomyTeeData["name"] = item.industryName;
              taxonomyTee.push(taxonomyTeeData);
            }
            valueChainIds.push(item.industryId);
          });
      });
      let count = _.countBy(valueChainIds);
      let countarray = Object.entries(count);
      countarray.map((d) => {
        if (selectedDeliverable.length == d[1]) {
          commanIds.push(d[0]);
        }
      });
      let valueChainData = _.filter(taxonomyTee, function (element) {
        return _.find(commanIds, function (name) {
          return element.id === name;
        });
      });
      setValueChainData(valueChainData);
    }
  };

  // Post call
  const taxonomyBulkTagging = async () => {
    dispatch(visitRecord());
    const selectedDeliverable = localStorage.getItem("selectedDeliverable") ? JSON.parse(localStorage.getItem("selectedDeliverable")) : [];
    if (selectedDeliverable.length) {
      const params = {};
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedDeliverable],
      ];
      params.fields = ["deliverableIndustryTag"];
      const companyIsLive = await fetchDeliverables(params);
      dispatch(hideLoader());
      if (companyIsLive) {
        dispatch(showLoader());
        setInitialState(selectedDeliverable);
        setShowTaxonomyBulk(true);
        companyIsLive?.data?.data && fetchValuchainData(companyIsLive.data.data, selectedDeliverable);
      }
    } else {
      dispatch(actionError("Please select deliverable."));
    }
  };

  const addTaxonomyCheckedNodes = (id) => {
    let taxonomyCheck = deliverableTaxonomy.taxonomyCheckedNodes;
    let taxonomyUnCheck = deliverableTaxonomy.taxonomyUnCheckedNodes;

    // for initial nodes
    let initialCheckedNodes = deliverableTaxonomy.initialTechnologyCheckedNodes;
    if (initialCheckedNodes.includes(id)) {
      initialCheckedNodes.splice(initialCheckedNodes.indexOf(id), 1);
      dispatch(setInitialCheckedTechnologyNodes(initialCheckedNodes));
    }
    dispatch(setInitialCheckedTechnologyNodes([...deliverableTaxonomy.initialTechnologyCheckedNodes, id]));

    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setTaxonomyCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setTaxonomyUnCheckedNodes([...deliverableTaxonomy.taxonomyUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setTaxonomyUnCheckedNodes(taxonomyUnCheck));
    }
    dispatch(setTaxonomyCheckedNodes([...deliverableTaxonomy.taxonomyCheckedNodes, id]));
  };

  // KIA
  const handleSubmitKia = async (fetchGridData) => {
    let foo = {
      deliverableIds: initalState,
      taxonomyTagging: deliverableTaxonomy.kiaCheckedNodes,
      taxonomyUnTagging: deliverableTaxonomy.kiaUnCheckedNodes,
    };

    if (deliverableTaxonomy?.initialKiaCheckedNodes.length == 0) {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              setTimeout(() => {
                dispatch(hideLoader());
              }, 0);
            },
            cancelCallback: async () => {
              setShowTaxonomyBulk(false);
            },

            okText: "Ok",
            cancelText: "Cancel",
            content: "There is no change observed in taxonomy tagging, press Ok to continue or press Cancel to close.",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    } else {
      dispatch(
        showAlertBox({
          okCallback: () => {
            setTimeout(() => {
              dispatch(showLoader());
              saveDeliverableKiaTags(foo)
                .then((result) => {
                  dispatch(actionSuccess("Taxonomy updated successfully"));
                  dispatch(setResetRedux());
                  setShowTaxonomyBulk(false);
                  dispatch(hideLoader());
                  fetchGridData();
                })
                .catch((err) => {
                  dispatch(hideLoader());
                  dispatch(actionError("Please select deliverable"));
                });
            }, 0);
          },
          okText: "Submit",
          cancelText: "Cancel",
          content: "Are you sure you want to update the tagging?",
          title: "dialogAlertCssWarning",
        })
      );
    }
  };

  // Get call for bulk tagging
  const fetchKiaTagging = () => {
    setListClass("select-lists bulktagging");
    setCssClass("treeClass");
    const selectedDeliverable = localStorage.getItem("selectedDeliverable") ? JSON.parse(localStorage.getItem("selectedDeliverable")) : [];

    if (selectedDeliverable.length) {
      setBulkCheck([]);
      const params = { limit: gridState.recordsPerPage };
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedDeliverable],
      ];
      params.fields = ["deliverableKiaTags"];
      const contentTaxonomy = fetchDeliverables(params);
      contentTaxonomy
        .then((res) => {
          let foo = [];

          let count;
          res.data.data.map((value) => {
            value.deliverableKiaTags.length > 0 &&
              value.deliverableKiaTags.map((v) => {
                foo.push(v.kiaId);
                count = {};
                for (const element of foo) {
                  if (count[element]) {
                    count[element] += 1;
                  } else {
                    count[element] = 1;
                  }
                }
              });

            let checked = Object.entries(count);
            let commonIds = [];
            checked.map((d) => {
              if (selectedDeliverable.length == d[1]) {
                commonIds.push(d[0]);
              }
            });

            commonIds.map((element) => {
              value.deliverableKiaTags.length > 0 &&
                value.deliverableKiaTags.map((v) => {
                  if (v.kiaId === element) {
                    commonIds.push(v.kiaIndustryId);
                  }
                });
            });

            commonIds = [...new Set(commonIds)];
            setBulkCheck([...deliverableTaxonomy.kiaCheckedNodes, ...commonIds]);
            setTaxonomyState([...commonIds]);
            dispatch(setKiaCheckedNodes([...deliverableTaxonomy.kiaCheckedNodes, ...commonIds]));
          });
        })
        .catch((err) => {
          console.log("err fetchProductTaxonomy :: ", err);
        });
    }
  };

  const addKiaCheckedNodes = (id) => {
    let taxonomyCheck = deliverableTaxonomy.kiaCheckedNodes;
    let taxonomyUnCheck = deliverableTaxonomy.kiaUnCheckedNodes;

    // for initial nodes
    let initialCheckedNodes = deliverableTaxonomy.initialKiaCheckedNodes;
    if (initialCheckedNodes.includes(id)) {
      initialCheckedNodes.splice(initialCheckedNodes.indexOf(id), 1);
      dispatch(setInitialCheckedKiaNodes(initialCheckedNodes));
    }
    dispatch(setInitialCheckedKiaNodes([...deliverableTaxonomy.initialKiaCheckedNodes, id]));

    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setKiaCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setKiaUnCheckedNodes([...deliverableTaxonomy.kiaUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setKiaUnCheckedNodes(taxonomyUnCheck));
    }
    dispatch(setKiaCheckedNodes([...deliverableTaxonomy.kiaCheckedNodes, id]));
  };

  // Bulk tagging Value chain
  const handleSubmitValueChain = async (fetchGridData) => {
    let foo = {
      deliverableIds: initalState,
      taxonomyTagging: deliverableTaxonomy.valueChainCheckedNodes,
      taxonomyUnTagging: deliverableTaxonomy.valueChainUnCheckedNodes,
      transformingTechnologiesIds: deliverableTaxonomy.transformingCheckedNodes,
      transformingUnCheckedTechnologiesIds: deliverableTaxonomy.transformingUnCheckedNodes,
    };

    if (deliverableTaxonomy?.initialValueChainCheckedNodes.length == 0) {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              setTimeout(() => {
                dispatch(hideLoader());
              }, 0);
            },
            cancelCallback: async () => {
              setShowTaxonomyBulk(false);
            },

            okText: "Ok",
            cancelText: "Cancel",
            content: "There is no change observed in taxonomy tagging, press Ok to continue or press Cancel to close.",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    } else {
      dispatch(
        showAlertBox({
          okCallback: () => {
            setTimeout(() => {
              dispatch(showLoader());
              saveDeliverableValueChainTags(foo)
                .then((result) => {
                  dispatch(actionSuccess("Taxonomy updated successfully"));
                  dispatch(setResetRedux());
                  setShowTaxonomyBulk(false);
                  dispatch(hideLoader());
                  fetchGridData();
                })
                .catch((err) => {
                  dispatch(hideLoader());
                  dispatch(actionError("Please select deliverable"));
                });
            }, 0);
          },
          okText: "Submit",
          cancelText: "Cancel",
          content: "Are you sure you want to update the tagging?",
          title: "dialogAlertCssWarning",
        })
      );
    }
  };

  const fetchValueChainTagging = () => {
    setListClass("select-lists bulktagging");
    setCssClass("treeClass");
    const selectedDeliverables = localStorage.getItem("selectedDeliverable") ? JSON.parse(localStorage.getItem("selectedDeliverable")) : [];
    if (selectedDeliverables.length) {
      setBulkCheck([]);
      const params = { limit: gridState.recordsPerPage };
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedDeliverables],
      ];
      params.fields = ["deliverableIndustryValuechainTags"];
      const contentTaxonomy = fetchDeliverables(params);
      contentTaxonomy
        .then((res) => {
          let checkNodes = [];
          let checkTechNodes = [];
          let count;
          let countTech;
          res.data.data.map((value) => {
            value.deliverableIndustryValuechainTags.length > 0 &&
              value.deliverableIndustryValuechainTags.map((v) => {
                checkNodes.push(v.valueChainId);
                count = {};
                for (const element of checkNodes) {
                  if (count[element]) {
                    count[element] += 1;
                  } else {
                    count[element] = 1;
                  }
                }

                // transformingTechnologies id
                v.transformingTechnologies &&
                  v.transformingTechnologies.map((ele) => {
                    checkTechNodes.push(ele.techId);
                    countTech = {};
                    for (const element of checkTechNodes) {
                      if (countTech[element]) {
                        countTech[element] += 1;
                      } else {
                        countTech[element] = 1;
                      }
                    }
                  });
              });
            let checked = count && Object.entries(count);
            let commonIds = [];
            checked &&
              checked.map((d) => {
                if (selectedDeliverables.length == d[1]) {
                  commonIds.push(d[0]);
                }
              });

            let transformingtechnology = countTech && Object.entries(countTech);
            let transformingtechnologyCommanId = [];
            transformingtechnology &&
              transformingtechnology.map((d) => {
                if (selectedDeliverables.length == d[1]) {
                  transformingtechnologyCommanId.push(d[0]);
                }
              });
            setBulkCheck([
              ...deliverableTaxonomy.valueChainCheckedNodes,
              ...commonIds,
              ...deliverableTaxonomy.transformingCheckedNodes,
              ...transformingtechnologyCommanId,
            ]);
            setTaxonomyState([...commonIds]);
            setTaxonomyTransformationState([...transformingtechnologyCommanId]);
            dispatch(setValueChainCheckedNodes([...deliverableTaxonomy.valueChainCheckedNodes, ...commonIds]));
            dispatch(setTransformingCheckedNodes([...deliverableTaxonomy.transformingCheckedNodes, ...transformingtechnologyCommanId]));
          });
        })
        .catch((err) => {
          console.log("err fetchProductTaxonomy :: ", err);
        });
    }
  };

  const addValueChainCheckedNodes = (id) => {
    let taxonomyCheck = deliverableTaxonomy.valueChainCheckedNodes;
    let taxonomyUnCheck = deliverableTaxonomy.valueChainUnCheckedNodes;

    // for initial nodes
    let initialCheckedNodes = deliverableTaxonomy.initialValueChainCheckedNodes;
    if (initialCheckedNodes.includes(id)) {
      initialCheckedNodes.splice(initialCheckedNodes.indexOf(id), 1);
      dispatch(setInitialCheckedValueChainNodes(initialCheckedNodes));
    }
    dispatch(setInitialCheckedValueChainNodes([...deliverableTaxonomy.initialValueChainCheckedNodes, id]));

    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setValueChainCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setValueChainUnCheckedNodes([...deliverableTaxonomy.valueChainUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setValueChainUnCheckedNodes(taxonomyUnCheck));
    }

    dispatch(setValueChainCheckedNodes([...deliverableTaxonomy.valueChainCheckedNodes, id]));
  };

  const addValueChainTransformingCheckedNodes = (id) => {
    let taxonomyCheck = deliverableTaxonomy.transformingCheckedNodes;
    let taxonomyUnCheck = deliverableTaxonomy.transformingUnCheckedNodes;
    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setTransformingCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setTransformingUnCheckedNodes([...deliverableTaxonomy.transformingUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setTransformingUnCheckedNodes(taxonomyUnCheck));
    }
    dispatch(setTransformingCheckedNodes([...deliverableTaxonomy.transformingCheckedNodes, id]));
  };

  // sgf
  const handleSubmitTrend = async (fetchGridData) => {
    let foo = {
      deliverableIds: initalState,
      taxonomyTagging: deliverableTaxonomy.trendCheckedNodes,
      taxonomyUnTagging: deliverableTaxonomy.trendUnCheckedNodes,
      sgfIds: deliverableTaxonomy.initialSgfCheckedNodes,
    };

    if (deliverableTaxonomy?.initialTrendCheckedNodes.length == 0) {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              setTimeout(() => {
                dispatch(hideLoader());
              }, 0);
            },
            cancelCallback: async () => {
              setShowTaxonomyBulk(false);
            },

            okText: "Ok",
            cancelText: "Cancel",
            content: "There is no change observed in taxonomy tagging, press Ok to continue or press Cancel to close.",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    } else {
      dispatch(
        showAlertBox({
          okCallback: async () => {
            setTimeout(() => {
              dispatch(showLoader());
              saveDeliverableTrendTags(foo)
                .then((result) => {
                  dispatch(actionSuccess("Taxonomy updated successfully"));
                  dispatch(setResetRedux());
                  setShowTaxonomyBulk(false);
                  dispatch(hideLoader());
                  fetchGridData();
                })
                .catch((err) => {
                  dispatch(hideLoader());
                  dispatch(actionError("Please select deliverable"));
                });
            }, 0);
          },
          okText: "Submit",
          cancelText: "Cancel",
          content: "Are you sure you want to update the tagging?",
          title: "dialogAlertCssWarning",
        })
      );
    }
  };

  // Get call for bulk tagging
  const fetchTrendTagging = () => {
    setCssClass("treeClass trend");
    setListClass("select-lists bulktagging");
    const selectedDeliverable = localStorage.getItem("selectedDeliverable") ? JSON.parse(localStorage.getItem("selectedDeliverable")) : [];

    if (selectedDeliverable.length) {
      setBulkCheck([]);
      const params = { limit: gridState.recordsPerPage };
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedDeliverable],
      ];
      params.fields = ["deliverableIndustryTrendsTags"];
      const contentTaxonomy = fetchDeliverables(params);
      contentTaxonomy
        .then((res) => {
          let foo = [];
          let count;
          res.data.data.map((value) => {
            value.deliverableIndustryTrendsTags.length > 0 &&
              value.deliverableIndustryTrendsTags.map((v) => {
                foo.push(v.trendId);
                count = {};
                for (const element of foo) {
                  if (count[element]) {
                    count[element] += 1;
                  } else {
                    count[element] = 1;
                  }
                }
              });

            let checked = count && Object.entries(count);
            let commonIds = [];
            checked &&
              checked.map((d) => {
                if (selectedDeliverable.length == d[1]) {
                  commonIds.push(d[0]);
                }
              });
            setBulkCheck([...deliverableTaxonomy.trendCheckedNodes, ...commonIds]);
            setTaxonomyState([...commonIds]);
            dispatch(setTrendCheckedNodes([...deliverableTaxonomy.trendCheckedNodes, ...commonIds]));
          });
        })
        .catch((err) => {
          console.log("err fetchProductTaxonomy :: ", err);
        });
    }
  };

  const addTrendCheckedNodes = (taxonomyId) => {
    let id = taxonomyId.split("-")[1];
    let sgfId = taxonomyId.split("-")[0];
    let taxonomyCheck = deliverableTaxonomy.trendCheckedNodes;
    let taxonomyUnCheck = deliverableTaxonomy.trendUnCheckedNodes;

    // for initial nodes
    let initialCheckedNodes = deliverableTaxonomy.initialTrendCheckedNodes;
    if (initialCheckedNodes.includes(id)) {
      initialCheckedNodes.splice(initialCheckedNodes.indexOf(id), 1);
      dispatch(setInitialCheckedTrendNodes(initialCheckedNodes));
    }
    dispatch(setInitialCheckedTrendNodes([...deliverableTaxonomy.initialTrendCheckedNodes, id]));

    // set checked sgf node
    dispatch(setInitialCheckedSgfNodes([...deliverableTaxonomy.initialSgfCheckedNodes, sgfId]));

    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setTrendCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setTrendUnCheckedNodes([...deliverableTaxonomy.trendUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setTrendUnCheckedNodes(taxonomyUnCheck));
    }
    dispatch(setTrendCheckedNodes([...deliverableTaxonomy.trendCheckedNodes, id]));
  };

  // Bulk tagging for business objective
  const handleSubmitObjective = async (fetchGridData) => {
    let taxonomy = {
      deliverableIds: initalState,
      deliverableObjectiveTaxonomy: deliverableTaxonomy.objectiveTaxonomyCheckedNodes,
      deliverableUnCheckObjectiveTaxonomy: deliverableTaxonomy.objectiveTaxonomyUnCheckedNodes,
    };

    if (deliverableTaxonomy?.initialObjectiveCheckedNodes.length == 0) {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              setTimeout(() => {
                dispatch(hideLoader());
              }, 0);
            },
            cancelCallback: async () => {
              setShowTaxonomyBulk(false);
            },

            okText: "Ok",
            cancelText: "Cancel",
            content: "There is no change observed in taxonomy tagging, press Ok to continue or press Cancel to close.",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    } else {
      dispatch(
        showAlertBox({
          okCallback: () => {
            setTimeout(() => {
              dispatch(showLoader());
              saveDeliverableBusinessObjectiveTaxonomy(taxonomy)
                .then((result) => {
                  dispatch(actionSuccess("Taxonomy updated successfully"));
                  dispatch(setResetRedux());
                  setShowTaxonomyBulk(false);
                  dispatch(hideLoader());
                  fetchGridData();
                })
                .catch((err) => {
                  dispatch(hideLoader());
                  dispatch(actionError("Please select deliverable"));
                });
            }, 0);
          },
          okText: "Submit",
          cancelText: "Cancel",
          content: "Are you sure you want to update the tagging?",
          title: "dialogAlertCssWarning",
        })
      );
    }
  };

  const fetchObjectiveTagging = () => {
    setListClass("select-lists bulktagging");
    setCssClass("treeClass");
    const selectedDeliverable = localStorage.getItem("selectedDeliverable") ? JSON.parse(localStorage.getItem("selectedDeliverable")) : [];
    if (selectedDeliverable.length) {
      setBulkCheck([]);
      const params = { limit: gridState.recordsPerPage };
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedDeliverable],
      ];
      params.fields = ["deliverableObjectiveTaxonomy"];
      const contentTaxonomy = fetchDeliverables(params);
      contentTaxonomy
        .then((res) => {
          let foo = [];
          let count;
          res.data.data.map((value) => {
            value.deliverableObjectiveTaxonomy.length > 0 &&
              value.deliverableObjectiveTaxonomy.map((v) => {
                foo.push(v.taxonomyId);
                count = {};
                for (const element of foo) {
                  if (count[element]) {
                    count[element] += 1;
                  } else {
                    count[element] = 1;
                  }
                }
              });

            let checked = Object.entries(count);
            let commonIds = [];
            checked.map((d) => {
              if (selectedDeliverable.length == d[1]) {
                commonIds.push(d[0]);
              }
            });

            setBulkCheck([...deliverableTaxonomy.objectiveTaxonomyCheckedNodes, ...commonIds]);
            dispatch(setObjectiveTaxonomyCheckedNodes([...deliverableTaxonomy.objectiveTaxonomyCheckedNodes, ...commonIds]));
          });
        })
        .catch((err) => {
          console.log("err fetchProductTaxonomy :: ", err);
        });
    }
  };

  const addObjectiveCheckedNodes = (id) => {
    let taxonomyCheck = deliverableTaxonomy.objectiveTaxonomyCheckedNodes;
    let taxonomyUnCheck = deliverableTaxonomy.objectiveTaxonomyUnCheckedNodes;

    // for initial nodes
    let initialCheckedNodes = deliverableTaxonomy.initialObjectiveCheckedNodes;
    if (initialCheckedNodes.includes(id)) {
      initialCheckedNodes.splice(initialCheckedNodes.indexOf(id), 1);
      dispatch(setInitialCheckedObjectiveNodes(initialCheckedNodes));
    }
    dispatch(setInitialCheckedObjectiveNodes([...deliverableTaxonomy.initialObjectiveCheckedNodes, id]));

    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setObjectiveTaxonomyCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setObjectiveTaxonomyUnCheckedNodes([...deliverableTaxonomy.objectiveTaxonomyUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setObjectiveTaxonomyUnCheckedNodes(taxonomyUnCheck));
    }

    dispatch(setObjectiveTaxonomyCheckedNodes([...deliverableTaxonomy.objectiveTaxonomyCheckedNodes, id]));
  };

  //
  let tabNames;
  if (productVariant == PRODUCT_VARIANT.INSIDER) {
    tabNames = taxonomyTaggingTabName ? ["Tag Industry"] : ["By Industry"];
  } else {
    tabNames = taxonomyTaggingTabName
      ? ["Tag Technology", "Tag Key Impact Area", "Tag Value Chain", "Tag Business Objective"]
      : ["By Technology", "By Key Impact Area", "By Value Chain", "By Business Objective"];
  }

  // handle save for taxonomy bluk tagging
  const handleSave = (selectedIndex, fetchGridData) => {
    if (productVariant === PRODUCT_VARIANT.WHATNEXT) {
      if (selectedIndex == 0) {
        handleSubmitTechnology(fetchGridData);
      }
      if (selectedIndex == 1) {
        handleSubmitKia(fetchGridData);
      }
      if (selectedIndex == 2) {
        handleSubmitValueChain(fetchGridData);
      }
      if (selectedIndex == 3) {
        handleSubmitObjective(fetchGridData);
      }
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      if (selectedIndex == 0) {
        handleSubmitTrend(fetchGridData);
      }
      if (selectedIndex == 1) {
        handleSubmitObjective(fetchGridData);
      }
    }
  };

  const handleTaxonomyTagging = (args, selectedIndex) => {
    let valueId = args?.data[0].id;
    if (productVariant === PRODUCT_VARIANT.WHATNEXT) {
      if (selectedIndex == 0) {
        addTaxonomyCheckedNodes(valueId);
      }
      if (selectedIndex == 1) {
        addKiaCheckedNodes(valueId);
      }
      if (selectedIndex == 2) {
        handleValueChainValidation(args);
      }
      if (selectedIndex == 3) {
        addObjectiveCheckedNodes(valueId);
      }
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      if (selectedIndex == 0) {
        addTrendCheckedNodes(valueId);
      }
      if (selectedIndex == 1) {
        addObjectiveCheckedNodes(valueId);
      }
    }
  };

  const handleValueChainValidation = (args) => {
    if (args?.data[0]?.parentID == null) {
      addValueChainCheckedNodes(args?.data[0]?.id);
    }
    if (args?.data[0]?.parentID !== null) {
      addValueChainTransformingCheckedNodes(args?.data[0]?.id);
    }
  };

  // fetch TaxonomyTagging data for taxonomy bluk tagging
  const fetchTaxonomyTagging = (selectedIndex) => {
    if (productVariant === PRODUCT_VARIANT.WHATNEXT) {
      if (selectedIndex == 0) {
        fetchTechnologyTagging();
      }
      if (selectedIndex == 1) {
        fetchKiaTagging();
      }
      if (selectedIndex == 2) {
        fetchValueChainTagging();
      }
      if (selectedIndex == 3) {
        fetchObjectiveTagging();
      }
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      if (selectedIndex == 0) {
        fetchTrendTagging();
      }
      if (selectedIndex == 1) {
        fetchObjectiveTagging();
      }
    }
  };

  const setBulkCheckedNode = (selectedIndex) => {
    if (productVariant === PRODUCT_VARIANT.WHATNEXT) {
      selectedIndex == 0 && setBulkCheck([...deliverableTaxonomy.taxonomyCheckedNodes]);
      selectedIndex == 1 && setBulkCheck([...deliverableTaxonomy.kiaCheckedNodes]);
      selectedIndex == 2 && setBulkCheck([...deliverableTaxonomy.valueChainCheckedNodes, ...deliverableTaxonomy.transformingCheckedNodes]);
      selectedIndex == 3 && setBulkCheck([...deliverableTaxonomy.objectiveTaxonomyCheckedNodes]);
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      selectedIndex == 0 && setBulkCheck([...deliverableTaxonomy.trendCheckedNodes]);
      selectedIndex == 1 && setBulkCheck([...deliverableTaxonomy.objectiveTaxonomyCheckedNodes]);
    }
  };

  const resetAllTaxonnomy = (selectedIndex) => {
    if (productVariant === PRODUCT_VARIANT.WHATNEXT) {
      if (selectedIndex == 0) {
        dispatch(setTaxonomyCheckedNodes([...taxonomyState]));
      }
      if (selectedIndex == 1) {
        dispatch(setKiaCheckedNodes([...taxonomyState]));
      }
      if (selectedIndex == 2) {
        dispatch(setValueChainCheckedNodes([...taxonomyState]));
        dispatch(setTransformingCheckedNodes([...taxonomyTransformationState]));
      }
      if (selectedIndex == 3) {
        dispatch(setObjectiveTaxonomyCheckedNodes([...taxonomyState]));
      }
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      if (selectedIndex == 0) {
        dispatch(setTrendCheckedNodes([...taxonomyState]));
      }
      if (selectedIndex == 1) {
        dispatch(setObjectiveTaxonomyCheckedNodes([...taxonomyState]));
      }
    }
    setBulkCheck([...taxonomyState]);
  };
  const locationPaths = localStorage.getItem("locationPath") ? localStorage.getItem("locationPath") : [];

  const fetchDeliverableType = async () => {
    let filters = [];
    filters.push(["contentTypeProductVariant", "eq", "Insider"]);
    const response = await getContentType({ filters: filters });

    if (response?.data && response?.data?.data) {
      const resData = response.data?.data
        .map((v) => {
          if (v.contentType !== CONTENT_TYPE.PROFILES_COMPANIES_TECHNOLOGIES) {
            return { id: v.contentType, text: v.contentType };
          }
        })
        .filter((val) => val !== undefined);
      if (resData) {
        resData.sort((a, b) => a.text.localeCompare(b.text));
        setDeliverableType(resData);
      }
    }
  };

  const fetchContentType = async () => {
    let filters = [];
    filters.push(["contentTypeProductVariant", "eq", "InsiderV2"]);
    const response = await getContentType({ filters: filters });
    if (response?.data && response?.data?.data) {
      const resData = response.data.data
        .map((v) => {
          if (v.contentType !== CONTENT_TYPE.PROFILES_COMPANIES_TECHNOLOGIES && v.contentType !== "News") {
            return { id: v.contentType, text: v.contentType };
          }
        })
        .filter((val) => val !== undefined);

      if (resData) {
        resData.sort((a, b) => a.text.localeCompare(b.text));
        setDeliverableContentType(resData);
      }

      if (resData === undefined)
        return setDeliverableContentType(
          contentTypes
            .filter((item) => {
              return item.productVariant == productVariant;
            })
            .map((item) => {
              return {
                id: item.deliverableContentTypeValue,
                text: item.deliverableContentTypeValue,
              };
            })
        );
    }
  };

  const handlePreview = (deliverableId) => {
    let link = `/deliverable/${deliverableId}/preview`;
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      link = `${import.meta.env.VITE_INSIDER_V2_URL}preview/${deliverableId}?userId=${user?._id}`;
    }
    const win = window.open(link, "_blank");
    win.focus();
  };

  useEffect(() => {
    if (productVariant !== PRODUCT_VARIANT.WHATNEXT) {
      if (!deliverableContentType) fetchContentType();
    } else {
      setDeliverableContentType(
        contentTypes
          .filter((item) => {
            return item.productVariant == productVariant;
          })
          .map((item) => {
            return {
              id: item.deliverableContentTypeValue,
              text: item.deliverableContentTypeValue,
            };
          })
      );
    }
  }, []);

  useEffect(() => {
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      if (!deliverableType) fetchDeliverableType();
    } else {
      setDeliverableType(
        deliverableTypes
          .filter((item) => {
            return item.productVariant == productVariant;
          })
          .map((item) => {
            return {
              id: item.deliverableTypeValue,
              text: item.deliverableTypeValue,
            };
          })
      );
    }
  }, []);

  return (
    <div className="gennx-content-wrapper content-wrapper px-3">
      <div className="gennx-grid-container">
        {techTrendData && deliverableType && deliverableContentType && (
          <CommonDataGrid
            gridTitle="Deliverables"
            fetch={fetchDeliverableWithProductVariant}
            deleteOne={() => null}
            deleteMany={false}
            showLoader={showLoaderGrid}
            hideLoader={hideLoaderGrid}
            columnNames={deliverableCol}
            columnFields={columnFields}
            formatRowData={formatRowData}
            deleteId={deleteId}
            getMultiSelectedRows={(data) => {
              localStorage.setItem("selectedDeliverable", JSON.stringify([...data]));
            }}
            getMultiDeSelectedRows={(data) => {
              localStorage.setItem("selectedDeliverable", JSON.stringify([...data]));
            }}
            objectiveTaxonomyRight={accessActionTaxonomyBulkTagging}
            objectiveTaxonomyTag={objectiveTaxonomyTag}
            addNewRight={accessActionListingAddNew}
            addNew={() => history.push(`deliverable/add#basic`)}
            setFilterData={setFilterData}
            filterData={filterData}
            showFilterByTaxonomy={(productVariant !== PRODUCT_VARIANT.CHEERSIN ? true : false) && accessActionFilterByTaxonomy}
            boTagging={true}
            taxonomyBulkTagging={taxonomyBulkTagging}
            showTaxonomyBulk={showTaxonomyBulk}
            setShowTaxonomyBulk={setShowTaxonomyBulk}
            initalState={initalState}
            checked={bulkCheck}
            cssClass={cssClass}
            setCssClass={setCssClass}
            listClass={listClass}
            setListClass={setListClass}
            moduleName={moduleName}
            tabName={tabNames}
            handleTaxonomyTabName={setTaxonomyTaggingTabName}
            customDialogBoxClass="dialoagBoxClass"
            customArrowClass="arrow-top"
            bulkTaggingArrowTop="bulkTaggingArrowTop"
            handleSubmit={handleSave}
            handleTaxonomyTagging={handleTaxonomyTagging}
            fetchTaxonomyTagging={fetchTaxonomyTagging}
            setBulkCheckedNode={setBulkCheckedNode}
            resetAllTaxonnomy={resetAllTaxonnomy}
            valueChainData={valueChainData}
            accessPermissionBulk={accessActionTaxonomyBulkTagging}
            myRole={true}
            roleValue={roleValue}
            setRoleValue={setRoleValue}
            clearFilterByTaxonomy={locationPaths != "/deliverable" ? true : false}
          />
        )}
      </div>
    </div>
  );
};
