/*
================================================================
  Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
    File Description : Application Routes for Auth, User, Company, Product, News
----------------------------------------------------------------
  Creation Details
  Date Created				: 26/Jun/2020
  Author						: YOGESH N. GUPTA
================================================================
*/

import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";
import DefaultLayout from "../app/layout/defaultLayout";

import UserRoutes from "./userRoutes";
import ProductRoutes from "./productRoutes";
import CompanyRoutes from "./companyRoutes";
import Login from "../app/views/login";
import InternshipRegistration from "../app/views/outSourcingRegistration";
import ExpertRegistration from "../app/views/outSourcingRegistration/expert";
import FormRender from "../app/views/outSourcingRegistration/registration";
import ProductVariant from "../app/views/productVariant";
import NewsRoutes from "./newsRoute";
import PatentRoutes from "./patentRoute";
import VideoRoutes from "./videoRoute";
import ReportRoutes from "./reportRoutes";
import DataQcRoutes from "./dataQcRoutes";
import TaxonomyRoutes from "./taxonomyRoutes";
import DeliverableRoutes from "./deliverableRoutes";
import TemplateRoutes from "./templateRoutes";
import CustomFormsRoutes from "./customFormsRoutes";
import SurveyRoutes from "./surveyRoutes";
import AssessmentRoutes from "./assessmentRoutes";
import TrainingVideoRoutes from "./trainingVideoRoutes";
import BusinessObjectiveRoutes from "./businessObjectiveRoutes";
import PeopleRoutes from "./peopleRoutes";
import KiaRoutes from "./kiaRoutes";
import NotificationRoutes from "./notificationRoutes";
import ContentBlockRoutes from "./contentBlockRoutes";
import CustomizableWidgetsRoutes from "./customizableWidgetsRoutes";
import MyBriefcaseRoutes from "./myBriefcaseRoutes";
import ServicesRoutes from "./servicesRoutes";
import DataUploadRoutes from "./dataUploadRoutes";
import SearchRoutes from "./searchRoutes";
import HelpdeskRoutes from "./helpdeskRoutes";
import SubscriptionRoutes from "./subscriptionRoutes";
import PersonalizationRoutes from "./personalizationRoutes";
import CustomProjectsRoutes from "./customProjectsRoutes";
import EnterpriseTaxonomyRoutes from "./enterpriseTaxonomyRoutes";
import DataModelLibraryRoutes from "./dataModelLibraryRoutes";
import VisulizationLibraryRoutes from "./visulizationLibrary";
import OnePetroRoutes from "./OnePetroRoutes";
import AutoLogin from "../app/views/autoLogin";
const DeliverablePreview = React.lazy(() => import("../app/views/deliverables/manageDeliverables/component/preview"));

const Routes = () => (
  <>
    <Switch>
      <DefaultLayout exact path="/" component={Login} pageHeading="GenNx CMS - Login" />
      <DefaultLayout exact path="/login" component={Login} pageHeading="GenNx CMS - Login" />
      <DefaultLayout exact path="/signup-expert" component={ExpertRegistration} pageHeading="WhatNext - Expert Community" />
      <DefaultLayout exact path="/signup-internship" component={InternshipRegistration} pageHeading="WhatNext - Internship Registration" />
      <DefaultLayout exact path="/signup-internship-insider" component={InternshipRegistration} pageHeading="Insider - Internship Registration" />
      <DefaultLayout exact path="/register/:formUniqueName/:invitationId?" component={FormRender} pageHeading="Registration" />
      <DefaultLayout exact path="/selector" component={ProductVariant} pageHeading="GenNx CMS - Login" />
      <DefaultLayout exact path="/auto-login" component={AutoLogin} pageHeading="GenNx CMS - Auto Login" />
    </Switch>
    <Suspense fallback={<div className="text-center">Loading...</div>}>
      <Switch>
        <DefaultLayout exact path="/deliverable/:deliverableId/preview" component={DeliverablePreview} pageHeading="Deliverable Preview" />
      </Switch>
    </Suspense>
    <UserRoutes />
    <DataQcRoutes />
    <CompanyRoutes />
    <ProductRoutes />
    <NewsRoutes />
    <VideoRoutes />
    <ReportRoutes />
    <TaxonomyRoutes />
    <DeliverableRoutes />
    <TemplateRoutes />
    <CustomFormsRoutes />
    <BusinessObjectiveRoutes />
    <SurveyRoutes />
    <AssessmentRoutes />
    <TrainingVideoRoutes />
    <PeopleRoutes />
    <PatentRoutes />
    <KiaRoutes />
    <ContentBlockRoutes />
    <ServicesRoutes />
    <CustomizableWidgetsRoutes />
    <NotificationRoutes />
    <DataUploadRoutes />
    <SearchRoutes />
    <MyBriefcaseRoutes />
    <HelpdeskRoutes />
    <SubscriptionRoutes />
    <PersonalizationRoutes />
    <CustomProjectsRoutes />
    <EnterpriseTaxonomyRoutes />
    <DataModelLibraryRoutes />
    <VisulizationLibraryRoutes />
    <OnePetroRoutes />
    {/* <NotFoundRoutes />	 */}
  </>
);

export default Routes;
Route.propTypes = {
  computedMatch: PropTypes.object,
  path: PropTypes.string,
  exact: PropTypes.bool,
  strict: PropTypes.bool,
  sensitive: PropTypes.bool,
  component: PropTypes.func,
  render: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  location: PropTypes.object,
};
