/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description :  Content Block Listing Page
 ---------------------------------------------------------------------------------
	Creation Details
	Date Created				: 12/April/2022
	Author						  : Amrutesh Devadas
================================================================
*/

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { getLoggedInUser, getProductVariant } from "../../../utilities";
import CommonDataGrid from "../../components/dataGrid";
import { StringFilter, DropDownFilter } from "../../components/dataGrid/fliters";
import {
  fetchContentBlock,
  deleteManyContentBlocks,
  saveContentBlockTaxonomyTags,
  saveContentBlockKiaTags,
  saveContentBlockTrendTags,
  saveContentBlockValueChainTags,
  updateContentBlock,
} from "../../../middleware/services/contentBlockApi";
import { showAlertBox } from "../../../middleware/actions/alertBoxAction";
import { actionSuccess, actionError, hideLoader, showLoader } from "../../../middleware/actions/utilityAction";
import { PRODUCT_VARIANT, COMMON_ACCESS_ACTION } from "../../../constants";
import ThreeDotMenu from "../../components/threeDotMenu";
import { resetInternalGrid, setMaintainedState, visitRecord } from "../../../middleware/actions/gridAction";
import { getDateFromMongoDate } from "../../../utilities/index";
import { setSideNavForcedActiveLink } from "../../../middleware/actions/sideNavAction";
import { fetchConfig } from "../../../middleware/services/cmsApi";
import { resetRedux, setFilteredTaxonomy } from "../../../middleware/actions/taxonomyFilterAction";
import {
  setInitialCheckedKiaNodes,
  setInitialCheckedSgfNodes,
  setInitialCheckedTechnologyNodes,
  setInitialCheckedTrendNodes,
  setInitialCheckedValueChainNodes,
  setKiaCheckedNodes,
  setKiaUnCheckedNodes,
  setResetRedux,
  setTaxonomyCheckedNodes,
  setTaxonomyUnCheckedNodes,
  setTransformingCheckedNodes,
  setTransformingUnCheckedNodes,
  setTrendCheckedNodes,
  setTrendUnCheckedNodes,
  setValueChainCheckedNodes,
  setValueChainUnCheckedNodes,
} from "../../../middleware/actions/taxonomyTaggingAction";
import _ from "underscore";

// specify breadcrumb here
const breadCrumbLinks = [{ linkUrl: "/content-block", linkName: "Content-Builder", linkActive: true }];

// company dashboard component
const ContentBlockDashboard = (props) => {
  let userId = getLoggedInUser();
  const [deleteId, setDeleteId] = useState(undefined);
  const [gridFilters, setGridFilters] = useState([]);
  const productVariant = getProductVariant();
  const history = useHistory();
  const gridState = useSelector((state) => state.gridState);
  let blockFilter = [];
  const taxonomyFilterRedux = useSelector((state) => state.taxonomyFilterState);
  const [filterData, setFilterData] = useState(taxonomyFilterRedux?.filteredTaxonomy ? taxonomyFilterRedux.filteredTaxonomy : []);
  const [initalState, setInitialState] = useState([]);
  const [bulkCheck, setBulkCheck] = useState([]);
  const [valueChainData, setValueChainData] = useState([]);
  const [cssClass, setCssClass] = useState("treeClass");
  const [listClass, setListClass] = useState("select-lists");
  const [showTaxonomyBulk, setShowTaxonomyBulk] = useState(false);
  const contentBlockTaxonomy = useSelector((state) => state.taxonomyTaggingState);
  let moduleName = props.accessRights.parentModuleName;
  const [taxonomyTaggingTabName, setTaxonomyTaggingTabName] = useState(false);
  const [taxonomyState, setTaxonomyState] = useState([]);
  const [taxonomyTransformationState, setTaxonomyTransformationState] = useState([]);

  /* Common access */
  const accessActionListingAddNew = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_NEW);
  const accessActionListingDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);
  const accessActionListingEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT);
  const accessActionTaxonomyBulkTagging = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.TAXONOMY_BULK_TAGGING);
  const accessActionFilterByTaxonomy = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.FILTER_BY_TAXONOMY);

  // Reset Redux
  useEffect(() => {
    const locationPath = localStorage.getItem("locationPath") ? localStorage.getItem("locationPath") : [];

    if (locationPath != `/content-block`) {
      dispatch(setFilteredTaxonomy([]));
      setFilterData([]);
      dispatch(resetRedux());
      localStorage.setItem("locationPath", window.location.pathname);
    }
  }, []);
  const getCategoryBlock = async () => {
    let params = {};
    params.filters = [["configName", "eq", "content_builder_category"]];
    await fetchConfig(params).then((res) => {
      if (res.status === 200) {
        blockFilter = res.data.data[0].configValue.map((item) => {
          let dropDownObject = {
            id: item.categoryName,
            text: item.categoryName,
          };
          return dropDownObject;
        });
      }
    });
  };
  // specify column names for column fields in datGrid here
  const columnNames = {
    id: "id",
    "Block Name": "contentBlockName",
    "Created Date": "contentBlockCreatedDate",
    Status: "contentBlockStatus",
    "Published Date": "contentBlockPublishDate",
    "Type of Block": "contentBlockType",
    "Created By": "contentBlockCreatedUserFname",
    "Last Updated Date": "contentBlockModifiedDate",
    Published: "contentBlockIsPublish",
  };

  const contentBlockNameTemplate = (value) => {
    return (
      <span
        className="span-link"
        onClick={() => {
          history.push(`/content-block/${value.id}/edit#properties`, {
            contentBlockId: value.id,
            contentBlockName: value["Block Name"],
            contentBlockStatus: value["Status"],
          });
          dispatch(visitRecord());
        }}
      >
        {value["Block Name"]}
      </span>
    );
  };

  /* 
		@Description : Template Component for action column
	*/
  const actionTemplate = (value) => {
    return (
      <ThreeDotMenu
        rowID={value.id}
        {...(accessActionListingEdit
          ? {
              Edit: () => {
                history.push(`/content-block/${value.id}/edit#properties`, {
                  contentBlockId: value.id,
                  contentBlockName: value["Block Name"],
                  contentBlockStatus: value["Status"],
                });
                dispatch(visitRecord());
              },
            }
          : {})}
        {...(accessActionListingDelete
          ? {
              Delete: () => {
                dispatch(
                  showAlertBox({
                    okCallback: async () => {
                      const payload = new FormData();
                      payload.append("contentBlockModifiedUserId", userId._id);
                      payload.append("userFname", userId.user_fname);
                      payload.append("userLname", userId.user_lname);
                      payload.append("contentBlockIsDelete", "YES");
                      updateContentBlock(value.id, payload)
                        .then((response) => {
                          if (deleteId) setDeleteId(undefined);
                          setDeleteId(true);
                          dispatch(actionSuccess("Content Block deleted successfully"));
                          const params = {};
                          if (productVariant) params.filters = [["productVariant.productName", "eq", productVariant]];
                          params.filters.push(["contentBlockIsDelete", "eq", "NO"]);
                          fetchContentBlock(params).then((content) => {
                            formatRowData(content.data.data);
                          });
                        })
                        .catch((err) => {
                          dispatch(actionError(err.data?.message || "Failed to delete content Block"));
                        });
                    },
                    content: "Are you sure you want to delete?",
                    okText: "Delete",
                    cancelText: "Cancel",
                    title: "dialogAlertCssWarning",
                  })
                );
              },
            }
          : {})}
      />
    );
  };

  const contentBlockDateTemplate = (value) => {
    switch (value.column.headerText) {
      case "Last Updated Date":
        return !isNaN(Date.parse(value["Last Updated Date"])) ? <span>{getDateFromMongoDate(value["Last Updated Date"])}</span> : "";
      case "Published Date":
        return !isNaN(Date.parse(value["Published Date"])) ? <span>{getDateFromMongoDate(value["Published Date"])}</span> : "";
      case "Created Date":
        return !isNaN(Date.parse(value["Created Date"])) ? <span>{getDateFromMongoDate(value["Created Date"])}</span> : "";
      default:
        return "";
    }
  };
  const contentBlockPublishTemplate = (value) => {
    return <>{value.Published === "YES" ? <span>YES</span> : <span>NO</span>}</>;
  };
  const publishFilter = (value) => {
    let sourceData = [
      { id: "YES", text: "YES" },
      { id: "NO", text: "NO" },
    ];
    return <DropDownFilter value={value} sourceData={sourceData} />;
  };

  const blockStatusFilter = (value) => {
    let sourceData = [
      { id: "In Progress", text: "In Progress" },
      { id: "Submitted", text: "Submitted" },
      { id: "Rejected", text: "Rejected" },
      { id: "Published", text: "Published" },
      { id: "Approved", text: "Approved" },
    ];
    return <DropDownFilter value={value} sourceData={sourceData} />;
  };
  const blockTypeFilter = (value) => {
    return <DropDownFilter value={value} sourceData={blockFilter} />;
  };

  // specify column fields for datGrid here
  // 4 types of field - String, Numeric, Date, Boolean

  const columnFields = [
    {
      field: "id",
      type: "String",
      visible: false,
      allowFiltering: false,
      showInColumnChooser: false,
      isPrimaryKey: true,
    },
    {
      field: "Block Name",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: false,
      width: 200,
      template: contentBlockNameTemplate,
      filterTemplate: StringFilter,
    },
    {
      field: "Type of Block",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: false,
      width: 200,
      filterTemplate: blockTypeFilter,
    },
    {
      field: "Created By",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      showInColumnChooser: false,
      width: 200,
      filterTemplate: StringFilter,
    },
    {
      field: "Created Date",
      type: "Date",
      template: contentBlockDateTemplate,
      width: 180,
      textAlign: "Center",
    },
    {
      field: "Last Updated Date",
      type: "Date",
      template: contentBlockDateTemplate,
      width: 190,
      textAlign: "Center",
    },
    {
      field: "Published",
      type: "String",
      width: 190,
      filterTemplate: publishFilter,
      template: contentBlockPublishTemplate,
      textAlign: "Center",
      filter: { operator: "equal" },
    },
    {
      field: "Status",
      type: "String",
      width: 150,
      textAlign: "Center",
      headerTextAlign: "Center",
      filterTemplate: blockStatusFilter,
    },
  ];
  if (accessActionListingDelete || accessActionListingEdit) {
    columnFields.unshift({
      field: null,
      type: "checkbox",
      width: 32,
      allowFiltering: false,
      showInColumnChooser: false,
      textAlign: "Center",
    });
    columnFields.push({
      field: "Action",
      type: "String",
      template: actionTemplate,
      allowSorting: false,
      allowFiltering: false,
      textAlign: "Right",
      headerTextAlign: "Center",
      width: 65,
      showInColumnChooser: false,
      freeze: "Right",
    });
  }

  // @Description formatRowData function required to format rowData for datGrid
  const formatRowData = (rowData) => {
    let formatedRowData = [];
    rowData.forEach((contentBlockData) => {
      let data = {};
      data.id = contentBlockData.id;
      data["Block Name"] = contentBlockData.contentBlockName;
      data["Type of Block"] = contentBlockData.contentBlockType;
      data["Created By"] = contentBlockData.contentBlockCreatedUserFname;
      data["Created Date"] = new Date(contentBlockData.contentBlockCreatedDate);
      data["Last Updated Date"] = new Date(contentBlockData.contentBlockModifiedDate);
      data["Published"] = contentBlockData.contentBlockIsPublish;
      data["Status"] = contentBlockData.contentBlockStatus;

      formatedRowData.push(data);
    });
    return formatedRowData;
  };
  const dispatch = useDispatch();
  const fetchContentBlockWithProductVariant = (params = {}) => {
    dispatch(resetInternalGrid());
    let defaultFilter = [["contentBlockIsDelete", "eq", "NO"]];
    let { filters = [] } = params;
    if (!gridState.recordVisited) {
      if (!params.skip) {
        params.skip = 0;
      }
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (gridState.recordVisited) {
      params = {
        filters: gridState.filtersParams,
        sort: gridState.sortRecords,
        skip: gridState.skipRecords,
        limit: gridState.recordsPerPage,
      };
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    if (!params.sort) params.sort = "contentBlockCreatedDate:desc";

    if (params.filters) {
      if (gridState.recordVisited) {
        params.filters = gridState.filtersParams;
      }
    }
    params.filters = [...filters, ...defaultFilter];
    setGridFilters(JSON.stringify(params.filters));
    if (params.filters?.length === 0) {
      params.filters = [...filters, ...defaultFilter];
      return fetchContentBlock(params);
    }

    if (Object.keys(filterData).length > 0) {
      let technologyFilter = [];
      let kiaFilter = [];
      let valueChainFilter = [];
      technologyFilter = filterData?.technology?.length > 0 ? ["contentBlockTechnologyTags.dtId", "in", filterData.technology] : [];
      kiaFilter = filterData?.kia?.length > 0 ? ["contentBlockKiaTags.kiaId", "in", filterData.kia] : [];
      valueChainFilter = filterData?.valueChain?.length > 0 ? ["contentBlockIndustryValuechainTags.valueChainId", "in", filterData.valueChain] : [];
      let trendfilter = filterData?.trend?.length > 0 ? ["contentBlockIndustryTrendsTags.trendId", "in", filterData.trend] : [];

      params.filters = [...filters, ...defaultFilter];
      technologyFilter.length > 0 && params.filters.push(technologyFilter);
      kiaFilter.length > 0 && params.filters.push(kiaFilter);
      valueChainFilter.length > 0 && params.filters.push(valueChainFilter);
      trendfilter.length > 0 && params.filters.push(trendfilter);
    }

    return fetchContentBlock(params);
  };
  const deleteManyFunc = (flag) => {
    const selectedContentBlocks = localStorage.getItem("selectedContentBlocks") ? JSON.parse(localStorage.getItem("selectedContentBlocks")) : [];
    if (flag) {
      if (selectedContentBlocks.length) {
        dispatch(
          showAlertBox({
            okCallback: () => {
              deleteManyContentBlocks({
                contentBlockIds: selectedContentBlocks,
              })
                .then((response) => {
                  if (response) {
                    if (deleteId) setDeleteId(undefined);
                    setDeleteId(true);
                    dispatch(actionSuccess("Selected record(s) deleted successfully"));
                    const params = {};
                    params.limit = 100;
                    fetchContentBlock(params).then((content) => {
                      formatRowData(content.data.data);
                    });
                  }
                })
                .catch((err) => {
                  dispatch(actionError(err.data?.message || "Failed to delete company"));
                });
            },
            content: "Are you sure, you want to delete records?",
            okText: "Delete",
            cancelText: "Cancel",
            title: "dialogAlertCssWarning",
          })
        );
      } else {
        dispatch(actionError("No record selected for deletion"));
      }
    }
  };
  useEffect(() => {
    localStorage.removeItem("selectedContentBlocks");
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/content-block"));
    getCategoryBlock();
    return () => {
      dispatch(setSideNavForcedActiveLink());
      dispatch(setBreadCrumb(breadCrumbLinks));
      localStorage.removeItem("selectedContentBlocks");
    };
  }, [dispatch]);

  const handleSubmitTechnology = async (fetchGridData) => {
    let payload = {
      contentBlockIds: initalState,
      taxonomyTagging: contentBlockTaxonomy.taxonomyCheckedNodes,
      taxonomyUnTagging: contentBlockTaxonomy.taxonomyUnCheckedNodes,
    };

    if (contentBlockTaxonomy?.initialTechnologyCheckedNodes.length == 0) {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              setTimeout(() => {
                dispatch(hideLoader());
              }, 0);
            },
            cancelCallback: async () => {
              setShowTaxonomyBulk(false);
            },

            okText: "Ok",
            cancelText: "Cancel",
            content: "There is no change observed in taxonomy tagging, press Ok to continue or press Cancel to close.",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    } else {
      dispatch(
        showAlertBox({
          okCallback: () => {
            setTimeout(() => {
              dispatch(showLoader());
              saveContentBlockTaxonomyTags(payload)
                .then((result) => {
                  dispatch(actionSuccess("Taxonomy updated successfully"));
                  dispatch(setResetRedux());
                  dispatch(hideLoader());
                  setShowTaxonomyBulk(false);
                  fetchGridData();
                })
                .catch((err) => {
                  dispatch(hideLoader());
                  dispatch(actionError("Please select content-block"));
                });
            }, 0);
          },
          okText: "Submit",
          cancelText: "Cancel",
          content: "Are you sure you want to update the tagging?",
          title: "dialogAlertCssWarning",
        })
      );
    }
  };
  const addKiaCheckedNodes = (id) => {
    let taxonomyCheck = contentBlockTaxonomy.kiaCheckedNodes;
    let taxonomyUnCheck = contentBlockTaxonomy.kiaUnCheckedNodes;

    // for initial nodes
    let initialCheckedNodes = contentBlockTaxonomy.initialKiaCheckedNodes;
    if (initialCheckedNodes.includes(id)) {
      initialCheckedNodes.splice(initialCheckedNodes.indexOf(id), 1);
      dispatch(setInitialCheckedKiaNodes(initialCheckedNodes));
    }
    dispatch(setInitialCheckedKiaNodes([...contentBlockTaxonomy.initialKiaCheckedNodes, id]));

    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setKiaCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setKiaUnCheckedNodes([...contentBlockTaxonomy.kiaUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setKiaUnCheckedNodes(taxonomyUnCheck));
    }
    dispatch(setKiaCheckedNodes([...contentBlockTaxonomy.kiaCheckedNodes, id]));
  };
  // Get call for bulk tagging
  const fetchTechnologyTagging = () => {
    setListClass("select-lists bulktagging");
    setCssClass("treeClass");
    const selectedContentBlocks = localStorage.getItem("selectedContentBlocks") ? JSON.parse(localStorage.getItem("selectedContentBlocks")) : [];

    if (selectedContentBlocks.length) {
      setBulkCheck([]);
      const params = { limit: gridState.recordsPerPage };
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedContentBlocks],
      ];
      params.fields = ["contentBlockTechnologyTags"];
      const contentTaxonomy = fetchContentBlock(params);
      contentTaxonomy
        .then((res) => {
          let foo = [];
          let count;
          res.data.data.map((value) => {
            value.contentBlockTechnologyTags.length > 0 &&
              value.contentBlockTechnologyTags.map((v) => {
                foo.push(v.dtId);
                count = {};
                for (const element of foo) {
                  if (count[element]) {
                    count[element] += 1;
                  } else {
                    count[element] = 1;
                  }
                }
              });

            let checked = count && Object.entries(count);
            let commonIds = [];
            checked &&
              checked.map((d) => {
                if (selectedContentBlocks.length == d[1]) {
                  commonIds.push(d[0]);
                }
              });
            setBulkCheck([...contentBlockTaxonomy.taxonomyCheckedNodes, ...commonIds]);
            setTaxonomyState([...commonIds]);
            dispatch(setTaxonomyCheckedNodes([...contentBlockTaxonomy.taxonomyCheckedNodes, ...commonIds]));
          });
        })
        .catch((err) => {
          console.log("err fetchProductTaxonomy :: ", err);
        });
    }
  };
  // Get call for bulk tagging
  const fetchKiaTagging = () => {
    setListClass("select-lists bulktagging");
    setCssClass("treeClass");
    const selectedContentBlocks = localStorage.getItem("selectedContentBlocks") ? JSON.parse(localStorage.getItem("selectedContentBlocks")) : [];

    if (selectedContentBlocks.length) {
      setBulkCheck([]);
      // dispatch(setResetRedux())
      const params = { limit: gridState.recordsPerPage };
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedContentBlocks],
      ];
      params.fields = ["contentBlockKiaTags"];
      const contentTaxonomy = fetchContentBlock(params);
      contentTaxonomy
        .then((res) => {
          let foo = [];

          let count;
          res.data.data.map((value) => {
            value.contentBlockKiaTags.length > 0 &&
              value.contentBlockKiaTags.map((v) => {
                foo.push(v.kiaId);
                count = {};
                for (const element of foo) {
                  if (count[element]) {
                    count[element] += 1;
                  } else {
                    count[element] = 1;
                  }
                }
              });

            let checked = count && Object.entries(count);
            let commonIds = [];
            checked &&
              checked.map((d) => {
                if (selectedContentBlocks.length == d[1]) {
                  commonIds.push(d[0]);
                }
              });
            commonIds &&
              commonIds.map((element) => {
                value.contentBlockKiaTags.length > 0 &&
                  value.contentBlockKiaTags.map((v) => {
                    if (v.kiaId === element) {
                      commonIds.push(v.kiaIndustryId);
                    }
                  });
              });
            commonIds = [...new Set(commonIds)];
            setBulkCheck([...contentBlockTaxonomy.kiaCheckedNodes, ...commonIds]);
            setTaxonomyState([...commonIds]);
            dispatch(setKiaCheckedNodes([...contentBlockTaxonomy.kiaCheckedNodes, ...commonIds]));
          });
        })
        .catch((err) => {
          console.log("err fetchProductTaxonomy :: ", err);
        });
    }
  };

  const fetchValuchainData = (data, selectedContentBlocks) => {
    if (data.length) {
      let valueChainIds = [];
      let commanIds = [];
      let taxonomyTee = [];
      data.map((value) => {
        value?.contentBlockIndustryTag?.length > 0 &&
          value.contentBlockIndustryTag.map((item) => {
            if (!valueChainIds.includes(item.industryId)) {
              let taxonomyTeeData = {};
              taxonomyTeeData["id"] = item.industryId;
              taxonomyTeeData["name"] = item.industryName;
              taxonomyTee.push(taxonomyTeeData);
            }
            valueChainIds.push(item.industryId);
          });
      });
      let count = _.countBy(valueChainIds);
      let countarray = Object.entries(count);
      countarray.map((d) => {
        if (selectedContentBlocks.length == d[1]) {
          commanIds.push(d[0]);
        }
      });
      let valueChainData = _.filter(taxonomyTee, function (element) {
        return _.find(commanIds, function (name) {
          return element.id === name;
        });
      });
      setValueChainData(valueChainData);
    }
  };

  // Post call
  const taxonomyBulkTagging = async () => {
    dispatch(visitRecord());
    const selectedContentBlocks = localStorage.getItem("selectedContentBlocks") ? JSON.parse(localStorage.getItem("selectedContentBlocks")) : [];
    if (selectedContentBlocks.length) {
      const params = {};
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedContentBlocks],
      ];
      params.fields = ["contentBlockIndustryTag"];
      const companyIsLive = await fetchContentBlock(params);
      dispatch(hideLoader());
      if (companyIsLive) {
        dispatch(showLoader());
        setInitialState(selectedContentBlocks);
        setShowTaxonomyBulk(true);
        companyIsLive?.data?.data && fetchValuchainData(companyIsLive.data.data, selectedContentBlocks);
      }
    } else {
      dispatch(actionError("Please select content block."));
    }
  };
  const handleSubmitKia = async (fetchGridData) => {
    let payload = {
      contentBlockIds: initalState,
      taxonomyTagging: contentBlockTaxonomy.kiaCheckedNodes,
      taxonomyUnTagging: contentBlockTaxonomy.kiaUnCheckedNodes,
    };

    if (contentBlockTaxonomy?.initialKiaCheckedNodes.length == 0) {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              setTimeout(() => {
                dispatch(hideLoader());
              }, 0);
            },
            cancelCallback: async () => {
              setShowTaxonomyBulk(false);
            },

            okText: "Ok",
            cancelText: "Cancel",
            content: "There is no change observed in taxonomy tagging, press Ok to continue or press Cancel to close.",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    } else {
      dispatch(
        showAlertBox({
          okCallback: () => {
            setTimeout(() => {
              dispatch(showLoader());
              saveContentBlockKiaTags(payload)
                .then((result) => {
                  dispatch(actionSuccess("Taxonomy updated successfully"));
                  dispatch(setResetRedux());
                  dispatch(hideLoader());
                  setShowTaxonomyBulk(false);
                  fetchGridData();
                })
                .catch((err) => {
                  dispatch(hideLoader());
                  dispatch(actionError("Please select content-block"));
                });
            }, 0);
          },
          okText: "Submit",
          cancelText: "Cancel",
          content: "Are you sure you want to update the tagging?",
          title: "dialogAlertCssWarning",
        })
      );
    }
  };

  const addTaxonomyCheckedNodes = (id) => {
    let taxonomyCheck = contentBlockTaxonomy.taxonomyCheckedNodes;
    let taxonomyUnCheck = contentBlockTaxonomy.taxonomyUnCheckedNodes;

    // for initial nodes
    let initialCheckedNodes = contentBlockTaxonomy.initialTechnologyCheckedNodes;
    if (initialCheckedNodes.includes(id)) {
      initialCheckedNodes.splice(initialCheckedNodes.indexOf(id), 1);
      dispatch(setInitialCheckedTechnologyNodes(initialCheckedNodes));
    }
    dispatch(setInitialCheckedTechnologyNodes([...contentBlockTaxonomy.initialTechnologyCheckedNodes, id]));

    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setTaxonomyCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setTaxonomyUnCheckedNodes([...contentBlockTaxonomy.taxonomyUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setTaxonomyUnCheckedNodes(taxonomyUnCheck));
    }
    dispatch(setTaxonomyCheckedNodes([...contentBlockTaxonomy.taxonomyCheckedNodes, id]));
  };
  const handleSubmitTrend = async (fetchGridData) => {
    let payload = {
      contentBlocksIds: initalState,
      taxonomyTagging: contentBlockTaxonomy.trendCheckedNodes,
      taxonomyUnTagging: contentBlockTaxonomy.trendUnCheckedNodes,
      sgfIds: contentBlockTaxonomy.initialSgfCheckedNodes,
    };

    if (contentBlockTaxonomy?.initialTrendCheckedNodes.length == 0) {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              setTimeout(() => {
                dispatch(hideLoader());
              }, 0);
            },
            cancelCallback: async () => {
              setShowTaxonomyBulk(false);
            },

            okText: "Ok",
            cancelText: "Cancel",
            content: "There is no change observed in taxonomy tagging, press Ok to continue or press Cancel to close.",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    } else {
      dispatch(
        showAlertBox({
          okCallback: () => {
            setTimeout(() => {
              dispatch(showLoader());
              saveContentBlockTrendTags(payload)
                .then((result) => {
                  dispatch(actionSuccess("Taxonomy updated successfully"));
                  dispatch(setResetRedux());
                  dispatch(hideLoader());
                  setShowTaxonomyBulk(false);
                  fetchGridData();
                })
                .catch((err) => {
                  dispatch(hideLoader());
                  dispatch(actionError("Please select content-block"));
                });
            }, 0);
          },
          okText: "Submit",
          cancelText: "Cancel",
          content: "Are you sure you want to update the tagging?",
          title: "dialogAlertCssWarning",
        })
      );
    }
  };
  // Get call for bulk tagging
  const fetchTrendTagging = () => {
    setCssClass("treeClass trend");
    setListClass("select-lists bulktagging");
    const selectedContentBlocks = localStorage.getItem("selectedContentBlocks") ? JSON.parse(localStorage.getItem("selectedContentBlocks")) : [];
    if (selectedContentBlocks.length) {
      setBulkCheck([]);
      const params = { limit: gridState.recordsPerPage };
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedContentBlocks],
      ];
      params.fields = ["contentBlockIndustryTrendsTags"];
      const contentTaxonomy = fetchContentBlock(params);
      contentTaxonomy
        .then((res) => {
          let foo = [];
          let count;
          res.data.data.map((value) => {
            value.contentBlockIndustryTrendsTags.length > 0 &&
              value.contentBlockIndustryTrendsTags.map((v) => {
                foo.push(v.trendId);
                count = {};
                for (const element of foo) {
                  if (count[element]) {
                    count[element] += 1;
                  } else {
                    count[element] = 1;
                  }
                }
              });
            let checked = count && Object.entries(count);
            let commonIds = [];
            checked &&
              checked.map((d) => {
                if (selectedContentBlocks.length == d[1]) {
                  commonIds.push(d[0]);
                }
              });
            setBulkCheck([...contentBlockTaxonomy.trendCheckedNodes, ...commonIds]);
            setTaxonomyState([...commonIds]);
            dispatch(setTrendCheckedNodes([...contentBlockTaxonomy.trendCheckedNodes, ...commonIds]));
          });
        })
        .catch((err) => {
          console.log("err fetchProductTaxonomy :: ", err);
        });
    }
  };

  const addTrendCheckedNodes = (taxonomyId) => {
    let id = taxonomyId.split("-")[1];
    let sgfId = taxonomyId.split("-")[0];
    let taxonomyCheck = contentBlockTaxonomy.trendCheckedNodes;
    let taxonomyUnCheck = contentBlockTaxonomy.trendUnCheckedNodes;

    // for initial nodes
    let initialCheckedNodes = contentBlockTaxonomy.initialTrendCheckedNodes;
    if (initialCheckedNodes.includes(id)) {
      initialCheckedNodes.splice(initialCheckedNodes.indexOf(id), 1);
      dispatch(setInitialCheckedTrendNodes(initialCheckedNodes));
    }
    dispatch(setInitialCheckedTrendNodes([...contentBlockTaxonomy.initialTrendCheckedNodes, id]));

    // set checked sgf node
    dispatch(setInitialCheckedSgfNodes([...contentBlockTaxonomy.initialSgfCheckedNodes, sgfId]));

    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setTrendCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setTrendUnCheckedNodes([...contentBlockTaxonomy.trendUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setTrendUnCheckedNodes(taxonomyUnCheck));
    }
    dispatch(setTrendCheckedNodes([...contentBlockTaxonomy.trendCheckedNodes, id]));
  };

  /* 
		@Description : Bulk tagging Value chain
	*/
  const handleSubmitValueChain = async (fetchGridData) => {
    let payload = {
      contentBlockIds: initalState,
      taxonomyTagging: contentBlockTaxonomy.valueChainCheckedNodes,
      taxonomyUnTagging: contentBlockTaxonomy.valueChainUnCheckedNodes,
      transformingTechnologiesIds: contentBlockTaxonomy.transformingCheckedNodes,
      transformingUnCheckedTechnologiesIds: contentBlockTaxonomy.transformingUnCheckedNodes,
    };

    if (contentBlockTaxonomy?.initialValueChainCheckedNodes.length == 0) {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              setTimeout(() => {
                dispatch(hideLoader());
              }, 0);
            },
            cancelCallback: async () => {
              setShowTaxonomyBulk(false);
            },

            okText: "Ok",
            cancelText: "Cancel",
            content: "There is no change observed in taxonomy tagging, press Ok to continue or press Cancel to close.",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    } else {
      dispatch(
        showAlertBox({
          okCallback: () => {
            setTimeout(() => {
              dispatch(showLoader());
              saveContentBlockValueChainTags(payload)
                .then((result) => {
                  dispatch(actionSuccess("Taxonomy updated successfully"));
                  dispatch(setResetRedux());
                  setShowTaxonomyBulk(false);
                  dispatch(hideLoader());
                  fetchGridData();
                })
                .catch((err) => {
                  dispatch(hideLoader());
                  dispatch(actionError("Please select contentBlock"));
                });
            }, 0);
          },
          okText: "Submit",
          cancelText: "Cancel",
          content: "Are you sure you want to update the tagging?",
          title: "dialogAlertCssWarning",
        })
      );
    }
  };

  const fetchValueChainTagging = () => {
    setListClass("select-lists bulktagging");
    setCssClass("treeClass");
    const selectedContentBlocks = localStorage.getItem("selectedContentBlocks") ? JSON.parse(localStorage.getItem("selectedContentBlocks")) : [];
    if (selectedContentBlocks.length) {
      setBulkCheck([]);
      const params = { limit: gridState.recordsPerPage };
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedContentBlocks],
      ];
      params.fields = ["contentBlockIndustryValuechainTags"];
      const contentTaxonomy = fetchContentBlock(params);
      contentTaxonomy
        .then((res) => {
          let checkNodes = [];
          let checkTechNodes = [];
          let count;
          let countTech;
          res.data.data.map((value) => {
            value.contentBlockIndustryValuechainTags.length > 0 &&
              value.contentBlockIndustryValuechainTags.map((v) => {
                checkNodes.push(v.valueChainId);
                count = {};
                for (const element of checkNodes) {
                  if (count[element]) {
                    count[element] += 1;
                  } else {
                    count[element] = 1;
                  }
                }

                // transformingTechnologies id
                v.transformingTechnologies &&
                  v.transformingTechnologies.map((ele) => {
                    checkTechNodes.push(ele.techId);
                    countTech = {};
                    for (const element of checkTechNodes) {
                      if (countTech[element]) {
                        countTech[element] += 1;
                      } else {
                        countTech[element] = 1;
                      }
                    }
                  });
              });
            let checked = count && Object.entries(count);
            let commonIds = [];
            checked &&
              checked.map((d) => {
                if (selectedContentBlocks.length == d[1]) {
                  commonIds.push(d[0]);
                }
              });

            let transformingtechnology = countTech && Object.entries(countTech);
            let transformingtechnologyCommanId = [];
            transformingtechnology &&
              transformingtechnology.map((d) => {
                if (selectedContentBlocks.length == d[1]) {
                  transformingtechnologyCommanId.push(d[0]);
                }
              });
            setBulkCheck([
              ...contentBlockTaxonomy.valueChainCheckedNodes,
              ...commonIds,
              ...contentBlockTaxonomy.transformingCheckedNodes,
              ...transformingtechnologyCommanId,
            ]);
            setTaxonomyState([...commonIds]);
            setTaxonomyTransformationState([...transformingtechnologyCommanId]);
            dispatch(setValueChainCheckedNodes([...contentBlockTaxonomy.valueChainCheckedNodes, ...commonIds]));
            dispatch(setTransformingCheckedNodes([...contentBlockTaxonomy.transformingCheckedNodes, ...transformingtechnologyCommanId]));
          });
        })
        .catch((err) => {
          console.log("err fetchProductTaxonomy :: ", err);
        });
    }
  };

  const addValueChainCheckedNodes = (id) => {
    let taxonomyCheck = contentBlockTaxonomy.valueChainCheckedNodes;
    let taxonomyUnCheck = contentBlockTaxonomy.valueChainUnCheckedNodes;

    // for initial nodes
    let initialCheckedNodes = contentBlockTaxonomy.initialValueChainCheckedNodes;
    if (initialCheckedNodes.includes(id)) {
      initialCheckedNodes.splice(initialCheckedNodes.indexOf(id), 1);
      dispatch(setInitialCheckedValueChainNodes(initialCheckedNodes));
    }
    dispatch(setInitialCheckedValueChainNodes([...contentBlockTaxonomy.initialValueChainCheckedNodes, id]));

    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setValueChainCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setValueChainUnCheckedNodes([...contentBlockTaxonomy.valueChainUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setValueChainUnCheckedNodes(taxonomyUnCheck));
    }

    dispatch(setValueChainCheckedNodes([...contentBlockTaxonomy.valueChainCheckedNodes, id]));
  };

  const addValueChainTransformingCheckedNodes = (id) => {
    let taxonomyCheck = contentBlockTaxonomy.transformingCheckedNodes;
    let taxonomyUnCheck = contentBlockTaxonomy.transformingUnCheckedNodes;
    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setTransformingCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setTransformingUnCheckedNodes([...contentBlockTaxonomy.transformingUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setTransformingUnCheckedNodes(taxonomyUnCheck));
    }
    dispatch(setTransformingCheckedNodes([...contentBlockTaxonomy.transformingCheckedNodes, id]));
  };

  let tabNames;
  if (productVariant == PRODUCT_VARIANT.INSIDER) {
    tabNames = taxonomyTaggingTabName ? ["Tag Industry"] : ["By Industry"];
  } else {
    tabNames = taxonomyTaggingTabName
      ? ["Tag Technology", "Tag Key Impact Area", "Tag Value Chain"]
      : ["By Technology", "By Key Impact Area", "By Value Chain"];
  }

  // handle save for taxonomy bluk tagging
  const handleSave = (selectedIndex, fetchGridData) => {
    if (productVariant === PRODUCT_VARIANT.WHATNEXT) {
      if (selectedIndex == 0) {
        handleSubmitTechnology(fetchGridData);
      }
      if (selectedIndex == 1) {
        handleSubmitKia(fetchGridData);
      }
      if (selectedIndex == 2) {
        handleSubmitValueChain(fetchGridData);
      }
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      if (selectedIndex == 0) {
        handleSubmitTrend(fetchGridData);
      }
    }
  };

  const handleTaxonomyTagging = (args, selectedIndex) => {
    let valueId = args?.data[0].id;
    if (productVariant === PRODUCT_VARIANT.WHATNEXT) {
      if (selectedIndex == 0) {
        addTaxonomyCheckedNodes(valueId);
      }
      if (selectedIndex == 1) {
        addKiaCheckedNodes(valueId);
      }
      if (selectedIndex == 2) {
        handleValueChainValidation(args);
      }
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      if (selectedIndex == 0) {
        addTrendCheckedNodes(valueId);
      }
    }
  };

  const handleValueChainValidation = (args) => {
    if (args?.data[0]?.parentID == null) {
      addValueChainCheckedNodes(args?.data[0]?.id);
    }
    if (args?.data[0]?.parentID !== null) {
      addValueChainTransformingCheckedNodes(args?.data[0]?.id);
    }
  };

  // fetch TaxonomyTagging data for taxonomy bluk tagging
  const fetchTaxonomyTagging = (selectedIndex) => {
    if (productVariant === PRODUCT_VARIANT.WHATNEXT) {
      if (selectedIndex == 0) {
        fetchTechnologyTagging();
      }
      if (selectedIndex == 1) {
        fetchKiaTagging();
      }
      if (selectedIndex == 2) {
        fetchValueChainTagging();
      }
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      if (selectedIndex == 0) {
        fetchTrendTagging();
      }
    }
  };

  const setBulkCheckedNode = (selectedIndex) => {
    if (productVariant === PRODUCT_VARIANT.WHATNEXT) {
      selectedIndex == 0 && setBulkCheck([...contentBlockTaxonomy.taxonomyCheckedNodes]);
      selectedIndex == 1 && setBulkCheck([...contentBlockTaxonomy.kiaCheckedNodes]);
      if (selectedIndex == 2) {
        setBulkCheck([...contentBlockTaxonomy.valueChainCheckedNodes, ...contentBlockTaxonomy.transformingCheckedNodes]);
      }
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      selectedIndex == 0 && setBulkCheck([...contentBlockTaxonomy.trendCheckedNodes]);
    }
  };

  const resetAllTaxonnomy = (selectedIndex) => {
    if (productVariant === PRODUCT_VARIANT.WHATNEXT) {
      if (selectedIndex == 0) {
        dispatch(setTaxonomyCheckedNodes([...taxonomyState]));
      }
      if (selectedIndex == 1) {
        dispatch(setTaxonomyCheckedNodes([...taxonomyState]));
      }
      if (selectedIndex == 2) {
        dispatch(setValueChainCheckedNodes([...taxonomyState]));
        dispatch(setTransformingCheckedNodes([...taxonomyTransformationState]));
      }
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      if (selectedIndex == 0) {
        dispatch(setTrendCheckedNodes([...taxonomyState]));
      }
    }
    setBulkCheck([...taxonomyState]);
  };
  const locationPaths = localStorage.getItem("locationPath") ? localStorage.getItem("locationPath") : [];

  return (
    <div className="gennx-content-wrapper content-wrapper px-3 ">
      <div className="gennx-grid-container">
        {
          <CommonDataGrid
            isChildGrid={false}
            fetch={fetchContentBlockWithProductVariant}
            columnNames={columnNames}
            columnFields={columnFields}
            formatRowData={formatRowData}
            deleteId={deleteId}
            getMultiSelectedRows={(data) => {
              localStorage.setItem("selectedContentBlocks", JSON.stringify([...data]));
            }}
            getMultiDeSelectedRows={(data) => {
              localStorage.setItem("selectedContentBlocks", JSON.stringify([...data]));
            }}
            deleteRight={true}
            deleteMany={accessActionListingDelete ? deleteManyFunc : null}
            addNewRight={accessActionListingAddNew}
            addNew={() => history.push(`/content-block/add#properties`)}
            setFilterData={setFilterData}
            filterData={filterData}
            showFilterByTaxonomy={(productVariant !== PRODUCT_VARIANT.CHEERSIN ? true : false) && accessActionFilterByTaxonomy}
            moduleName={moduleName}
            boTagging={false}
            taxonomyBulkTagging={taxonomyBulkTagging}
            showTaxonomyBulk={showTaxonomyBulk}
            setShowTaxonomyBulk={setShowTaxonomyBulk}
            initalState={initalState}
            checked={bulkCheck}
            tabName={tabNames}
            handleTaxonomyTabName={setTaxonomyTaggingTabName}
            customDialogBoxClass="dialoagBoxClass"
            customArrowClass="arrow-top"
            bulkTaggingArrowTop="bulkTaggingArrowTop"
            cssClass={cssClass}
            setCssClass={setCssClass}
            listClass={listClass}
            setListClass={setListClass}
            handleSubmit={handleSave}
            handleTaxonomyTagging={handleTaxonomyTagging}
            fetchTaxonomyTagging={fetchTaxonomyTagging}
            setBulkCheckedNode={setBulkCheckedNode}
            resetAllTaxonnomy={resetAllTaxonnomy}
            valueChainData={valueChainData}
            accessPermissionBulk={accessActionTaxonomyBulkTagging}
            clearFilterByTaxonomy={locationPaths != "/content-block" ? true : false}
          />
        }
      </div>
    </div>
  );
};

export default ContentBlockDashboard;
