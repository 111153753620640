import React, { useState, useEffect } from "react";
import Accordion from "../../../../../components/accordion";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { actionSuccess, actionError } from "../../../../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../../../../middleware/actions/alertBoxAction";
import ReactTooltip from "react-tooltip";
import {
  fetchCompanyDocumentsByCompanyId,
  deleteCompanyDocument,
  fetchCompanyDocumentUrl,
  editCompanyDocument,
  addCompanyDocument,
} from "../../../../../../middleware/services/companyApi";
import DocumentModal from "./documentModal";
import ThreeDotMenu from "../../../../../components/threeDotMenu";
import { COMMON_ACCESS_ACTION } from "../../../../../../constants";

const DocumentsBrochure = (props) => {
  const [documentListing, setDocumentListing] = useState([]);
  const [documentLoading, setDocumentLoading] = useState(props.companyId);
  const [documentId, setDocumentId] = useState(null);
  const [editDocumentData, setEditDocumentData] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  /* Common access */
  const accessPermissionAdd = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_DOCUMENTS);
  const accessPermissionEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT_DOCUMENTS);
  const accessPermissionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE_DOCUMENTS);

  const deleteSingleDocument = async (data) => {
    dispatch(
      showAlertBox({
        okCallback: async () => {
          await deleteCompanyDocument(data.companyId, data.Id)
            .then((res) => {
              if (res.status === 200) {
                dispatch(actionSuccess("Document deleted Successfully"));
              }
              getDocuments();
            })
            .catch((err) => {
              dispatch(actionError(err?.data?.message || "Something went wrong"));
            });
        },
        okText: "Delete",
        cancelText: "Cancel",
        content: "Are you sure you want to Delete ?",
        title: "dialogAlertCssWarning",
      })
    );
  };
  const onUpdateData = (companyId, documentId, data) => {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = editCompanyDocument(companyId, documentId, formData);
    return response
      .then((result) => {
        try {
          if (result.status === 200) {
            dispatch(actionSuccess("Company document updated successfully"));
            setIsOpen(false);
            getDocuments();
          } else {
            dispatch(actionError("Something went wrong"));
          }
        } catch (err) {
          dispatch(actionError(err ? `${err.status}:${err.statusText}` : "Something went wrong"));
        }
      })
      .catch((err) => {
        dispatch(actionError(err ? `${err.status}:${err.statusText}` : "Something went wrong"));
      });
  };
  const onSaveData = (companyId, data) => {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = addCompanyDocument(companyId, formData);
    return response
      .then((result) => {
        try {
          if (result.status === 200) {
            dispatch(actionSuccess("Company Document Saved Successfully"));
            setIsOpen(false);
            getDocuments();
            return { status: "success" };
          } else {
            dispatch(actionError("Something went wrong"));
          }
        } catch (err) {
          dispatch(actionError(err || "Something went wrong"));
        }
      })
      .catch((err) => {
        dispatch(actionError(err || "Something went wrong"));
      });
  };

  const getDocuments = () => {
    fetchCompanyDocumentsByCompanyId(props.companyId)
      .then((res) => {
        const docData = res.data?.data?.companyDocuments || [];
        setDocumentListing(docData);
        setDocumentLoading(false);
      })
      .catch((err) => {
        dispatch(actionError(err.data?.message || "Something went wrong"));
      });
  };

  useEffect(() => {
    getDocuments();
  }, []);

  return (
    <Accordion heading="Documents" stepCompleted={documentListing && documentListing.length > 0} step={8}>
      <div className="row px-3">
        <div className="table-responsive pt-2">
          {documentLoading ? (
            "Loading..."
          ) : (
            <table className="normal-table table table-bordered">
              <thead className="internal-grid">
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Attachment</th>
                  <th className="text-center internal-grid-action">Action</th>
                </tr>
              </thead>
              <tbody>
                {documentListing.map((item, i) => {
                  return (
                    <React.Fragment key={`doc-${i}`}>
                      <tr>
                        <td>{item.docTitle}</td>
                        <td>
                          {item.docDescription
                            .replace(/(<([^>]+)>|&nbsp;)/gi, "")
                            .substring(0, 40)
                            .concat(".....")}
                        </td>
                        <td>
                          <a href={fetchCompanyDocumentUrl(item.docFileName, item.docName)} download>
                            <i className="fas fa-download" data-tip="Download" /> {item.docName}
                          </a>
                          <ReactTooltip />
                        </td>
                        <td className="text-center internal-grid-action">
                          {accessPermissionEdit || accessPermissionDelete ? (
                            <ThreeDotMenu
                              rowID={item.id}
                              Edit={
                                accessPermissionEdit
                                  ? () => {
                                      setEditDocumentData(true);
                                      setIsOpen(true);
                                      setDocumentId(item.id);
                                    }
                                  : undefined
                              }
                              Delete={
                                accessPermissionDelete
                                  ? () => {
                                      deleteSingleDocument({ companyId: props.companyId, Id: item.id });
                                    }
                                  : undefined
                              }
                            />
                          ) : null}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
              {/* <tfoot>
							<tr>
								<td colSpan={3} />
								<td />
							</tr>
						</tfoot> */}
            </table>
          )}
          <div className="text-right">
            {accessPermissionAdd ? (
              <button
                type="button"
                className="btn btn-dark cy-btn"
                onClick={() => {
                  setEditDocumentData(false);
                  setIsOpen(true);
                }}
              >
                Add New
              </button>
            ) : null}
          </div>
        </div>
      </div>
      {isOpen && (
        <DocumentModal
          editDocumentData={editDocumentData}
          documentId={documentId}
          onSaveData={onSaveData}
          onUpdateData={onUpdateData}
          closeHandler={() => {
            setIsOpen(false);
          }}
          isOpen={isOpen}
          companyId={props.companyId}
        />
      )}
    </Accordion>
  );
};
export default DocumentsBrochure;
DocumentsBrochure.propTypes = {
  companyId: PropTypes.string,
  interfaceActionAccess: PropTypes.bool,
};
