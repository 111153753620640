import "../index.scss";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import { actionSuccess, showLoader, hideLoader, actionError, actionStart, showMessage } from "../../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import {
  fetchTaxonomyTechnology,
  saveTaxonomyTechnology,
  updateTaxonomyTechnology,
  deleteTaxonomyTechnology,
  updateTaxonomiesTechnology,
} from "../../../../middleware/services/cmsApi";
import SortableTree from "../../../components/treeView/sortableTree";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import Ascending from "../../../../assets/images/ascending.svg?react";
import Descending from "../../../../assets/images/descending.svg?react";
import Custom from "../../../../assets/images/custom.svg?react";
import SideBar from "./SideBar";
import { COMMON_ACCESS_ACTION } from "../../../../constants";
import { accessRightActionCheck } from "../../../../utilities";

const breadCrumbLinks = [];
const TaxomonyUI = (props) => {
  const { accessRights: aR } = props;
  const dispatch = useDispatch();

  const [technology, setTechnology] = useState([]);
  const [selectedTechnology, setSelectedTechnology] = useState(undefined);
  const [taxonomy, setTaxonomy] = useState([]);
  const [selectedTaxonomy, setSelectedTaxonomy] = useState(undefined);
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [treeCollapse, setTreeCollapse] = useState(true);
  const [firstRender, setFirstRender] = useState(true);
  const treeSort = [
    { id: 1, value: "ASC" },
    { id: 2, value: "DESC" },
    { id: 3, value: "CUSTOM" },
  ];
  const [sort, setSort] = useState("");
  const [treeSorting, setTreeSorting] = useState("");
  const [allowDragAndDrop, setAllowDragAndDrop] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [content, setContent] = useState();
  const [showAddEdit, setShowAddEdit] = useState(false);
  const [showInstruction, setShowInstruction] = useState(true);
  const [showAdd, setShowAdd] = useState(false);
  const [changeOrder, setChangeOrder] = useState(false);
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  /* Common access */
  const accessActionAdd = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.ADD);
  const accessActionEdit = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.EDIT);
  const accessActionDelete = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.DELETE);

  const saveUpdatedTaxonomy = async (data) => {
    let result = [];
    data.forEach((item) => {
      let resultObj = {
        id: item.id,
        dt_sort_order: item.taxonomySortOrder,
      };
      result.push(resultObj);
    });
    let params = {
      filters: [],
    };
    params.filters.push(["taxonomyRoot", "eq", selectedTechnology]);
    let payload = {
      taxonomies: result,
    };
    dispatch(showLoader());
    await updateTaxonomiesTechnology(params, payload)
      .then((res) => {
        dispatch(actionSuccess("updated successfully"));
        if (treeSorting != "CUSTOM") fetchTaxonomy(selectedTechnology);
        dispatch(hideLoader());
      })
      .catch((e) => {
        dispatch(hideLoader());
        dispatch(actionError("Something went wrong!"));
      });
  };
  /**
   * @description build json for tree
   * @param {Array} data
   * @param {String} parentId
   */
  const buildTreeJson = (data, parentId) => {
    const result = [];
    data.forEach((element) => {
      element["value"] = element.id;
      element["label"] = element.dtName;
      element["taxonomySortOrder"] = element.dtSortOrder;
      // element["dtDisplayname"] = element.dtDisplayname;
      element["showCheckbox"] = false;
      element["level"] = element.dtLevel;
      if (element["dtParentId"] == parentId) {
        const children = buildTreeJson(data, element["id"]);
        if (children.length > 0) {
          element["children"] = children;
        }
        result.push(element);
      }
    });
    return result;
  };

  /**
   * @description fetch taxonomy technology
   */
  const fetchTechnology = () => {
    fetchTaxonomyTechnology({ filters: [["dtParentId", "eq", 0]], sort: "dtName:asc", limit: 2000 })
      .then((res) => {
        let { data = [] } = res.data;
        setTechnology(data);
        fetchTaxonomy(data[0].id);
        setTreeSorting(data[0]?.dtSortSeq);
        if (data[0]?.dtSortSeq === "ASC") {
          setAllowDragAndDrop(false);
          setSort(1);
        }
        if (data[0]?.dtSortSeq === "DESC") {
          setAllowDragAndDrop(false);
          setSort(2);
        }
        if (data[0]?.dtSortSeq === "CUSTOM") {
          setAllowDragAndDrop(true);
          setSort(3);
        }

        setSelectedTechnology(data[0].id);
      })
      .catch((err) => {
        console.log("err fetchTechnology :: ", err);
      });
  };

  /**
   * @description fetch taxonomy technology by selected dtRoot
   * @param {String} dtRoot
   */
  const fetchTaxonomy = (dtRoot) => {
    dispatch(showLoader());
    setSelectedTaxonomy(undefined);
    fetchTaxonomyTechnology({
      fields: ["id", "dtName", "dtParentId", "dtDisplayname", "dtSortOrder", "dtParentName", "dtLevel"],
      filters: [["dtRoot", "eq", dtRoot]],
      sort: "dtSortOrder:asc",
      limit: 2000,
    })
      .then((res) => {
        dispatch(hideLoader());
        let { data = [] } = res.data;

        if (data.length) {
          const treeJosnArray = buildTreeJson(data, dtRoot);
          setTaxonomy(treeJosnArray);
          setTreeCollapse(true);
        }
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.log("err fetchTaxonomy :: ", err);
      });
  };

  /**
   * @description function to open add new technology modal
   */
  const openTechnologyAddModal = () => {
    if (selectedTaxonomy == undefined || selectedTaxonomy.length < 2) {
      setReRender(false);
      setShow(true);
      setShowInstruction(false);
      setShowAddEdit(true);
      setShowAdd(true);
      setEdit(false);
    } else {
      dispatch(showMessage("Please select single node."));
    }
  };

  /**
   * @description function to open edit technology modal
   */
  const openTechnologyEditModal = () => {
    if (selectedTaxonomy?.length == 1) {
      setReRender(false);
      setShow(true);
      setShowInstruction(false);
      setShowAddEdit(true);
      setEdit(true);
      setShowAdd(false);
    } else dispatch(showMessage("Please select single node."));
  };

  /**
   * @description tree node click handler
   * @param {String} dV
   */
  const handleClick = (dV) => {
    if (dV.length > 0) {
      setSelectedTaxonomy(dV);
      setShowAddEdit(true);
      setShowAdd(false);
      setEdit(false);
    } else {
      setSelectedTaxonomy(undefined);
      setShowAddEdit(false);
      setShowInstruction(true);
    }
  };

  /**
   * @description save new technology
   * @param {String} dtName
   * @param {String} dtDisplayname
   */
  const handleSubmitSaveTaxonomy = (dtName, dtDisplayname) => {
    if (dtName && dtName !== "") {
      dispatch(actionStart());
      let payload = {
        dtParentId: selectedTaxonomy ? selectedTaxonomy[0]?.value : selectedTechnology || technology[0].id,
        dtName: dtName,
        sortSeq: treeSorting,
      };
      if (dtDisplayname) payload["dtDisplayname"] = dtDisplayname;

      saveTaxonomyTechnology(payload)
        .then((res) => {
          dispatch(actionSuccess("Taxonomy added successfully"));
          fetchTaxonomy(selectedTechnology || technology[0].id);
          setShow(true);
          setReRender(true);
          setShowAddEdit(false);
          setShowAdd(false);
          setEdit(false);
          setShowInstruction(true);
        })
        .catch((err) => {
          dispatch(actionError("Add taxonomy failed"));
        });
    }
  };

  /**
   * @description update existing technology
   * @param {String} dtName
   * @param {String} dtDisplayname
   */
  const handleSubmitUpdateTaxonomy = (dtName = undefined, dtDisplayname = undefined) => {
    if ((dtName && dtName !== "") || (dtDisplayname && dtDisplayname !== "")) {
      if (selectedTaxonomy?.children?.length > 0) {
        dispatch(actionError("Can not edit parent node"));
        return true;
      }
      dispatch(actionStart());
      let payload = {};
      if (dtName && dtName !== "") payload.dtName = dtName;
      if (dtDisplayname && dtDisplayname !== "") payload.dtDisplayname = dtDisplayname;
      payload.sortSeq = treeSorting;
      updateTaxonomyTechnology(selectedTaxonomy[0].value, payload)
        .then((res) => {
          dispatch(actionSuccess("Taxonomy updated successfully"));
          fetchTaxonomy(selectedTechnology || technology[0].id);
          setReRender(true);
          setEdit(false);
          setShowAddEdit(false);
          setShowAdd(false);
          setShowInstruction(true);
        })
        .catch((err) => {
          dispatch(actionError("Update taxonomy failed"));
        });
    }
  };

  /**
   * @description delete technology
   */
  const deleteTaxonomy = () => {
    setTimeout(() => {
      dispatch(actionStart());
      let taxonomyIds = selectedTaxonomy.map((v) => {
        return v.id;
      });
      deleteTaxonomyTechnology(taxonomyIds)
        .then((res) => {
          dispatch(actionSuccess("Taxonomy deleted successfully"));
          fetchTaxonomy(selectedTechnology || technology[0].id);
          setReRender(true);
          setEdit(false);
          setShowAddEdit(false);
          setShowAdd(false);
          setShowInstruction(true);
        })
        .catch((err) => {
          let errMsg = err?.data?.message ? err?.data?.message : "Delete trend failed";
          if (err?.data?.statusCode == 451) {
            let string1 = errMsg.split("-");
            dispatch(actionError(`Following nodes can not be deleted, nodes are tagged in ${string1[0]} - ${string1[1]}`));
          } else {
            dispatch(actionError(errMsg));
          }
        });
    }, 0);
  };

  /**
   * @description function to open delete technology alert box
   */
  const deleteTechnology = () => {
    if (selectedTaxonomy) {
      if (selectedTaxonomy?.children?.length > 0) {
        dispatch(actionError("Can not delete parent node"));
        return true;
      }
      setReRender(false);
      dispatch(
        showAlertBox({
          okCallback: deleteTaxonomy,
          okText: "Delete",
          cancelText: "Cancel",
          content: "Do you want to delete taxonomy ? ",
          title: "dialogAlertCssWarning",
        })
      );
    } else dispatch(actionError("Select Taxonomy"));
  };

  const handleSequenceTaxonomy = (taxonomyId, payload) => {
    dispatch(showLoader());
    updateTaxonomyTechnology(taxonomyId, payload)
      .then((res) => {
        dispatch(hideLoader());
        if (technology?.length) {
          let updated = technology.findIndex((item) => {
            return item.id === taxonomyId;
          });
          technology[updated].dtSortSeq = payload?.dtSortSeq;
        }
      })
      .catch((err) => {
        dispatch(hideLoader());
        dispatch(actionError("something went wrorn"));
      });
  };

  useEffect(() => {
    fetchTechnology();
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => {
      dispatch(setBreadCrumb());
    };
  }, [dispatch]);

  useEffect(() => {
    const options = {
      // damping:0.1,
      thumbMinSize: 20,
      alwaysShowTracks: true,
    };

    setTimeout(() => {
      let classExist = document.querySelector(".custom-is-searchable");
    }, 1000);
  }, []);

  useEffect(() => {
    if (changeOrder) {
      if (treeSorting === "ASC") {
        setSort(1);
      }
      if (treeSorting === "DESC") {
        setSort(2);
      }
      if (treeSorting === "CUSTOM") {
        setSort(3);
      }
    }
  }, [changeOrder]);

  function handleSorting(sortObject) {
    selectedTechnology && handleSequenceTaxonomy(selectedTechnology, { dtSortSeq: sortObject.value });
    if (sortObject.value === "CUSTOM") {
      setAllowDragAndDrop(true);
    }
  }

  /* Interface actions */
  useEffect(() => {
    const interfaceName = "Listing";
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, []);

  return (
    <div className="pl-0 pt-2 pb-0" style={{ overflowY: "hidden" }}>
      <div className="col-12 bg-white">
        <div className="row taxonomyHeader">
          <div className="col-md-4 pt-2" style={{ minWidth: "250px", maxWidth: "300px" }}>
            {technology.length > 0 ? (
              <DropDownListComponent
                value={selectedTechnology || technology[0].id}
                change={(value) => {
                  setSelectedTechnology(value.itemData?.id);
                  fetchTaxonomy(value.itemData?.id);
                  setTreeSorting(value.itemData?.dtSortSeq);
                  setSelectedTaxonomy({ label: value.itemData?.taxonomyName, value: value.itemData?.id });
                  if (value.taxonomySortSeq === "CUSTOM") setAllowDragAndDrop(true);
                }}
                cssClass={"customCss e-outline"}
                floatLabelType="Auto"
                popupHeight="250px"
                dataSource={[...technology]}
                fields={{ text: "dtName", value: "id" }}
                placeholder="Select Technology"
              />
            ) : null}
          </div>
          {treeSorting ? (
            <div className="pt-2 pr-0" style={{ width: "fitContent", display: "inline-block" }}>
              <div className="pt-2">
                <span
                  className="ml-2 ordering"
                  onClick={() => {
                    // setSort(1)
                    setTreeSorting("ASC");
                    handleSorting({ id: 1, value: "ASC" });
                    setChangeOrder(true);
                    setAllowDragAndDrop(false);
                  }}
                  style={treeSorting == "ASC" ? { color: "#F94F5E" } : { color: "black" }}
                >
                  <Ascending fill={treeSorting == "ASC" ? "#F94F5E" : "#939399"} />
                  Ascending
                </span>
                <span
                  className="ml-2 ordering"
                  onClick={() => {
                    // setSort(2)
                    setTreeSorting("DESC");
                    handleSorting({ id: 2, value: "DESC" });
                    setChangeOrder(true);
                    setAllowDragAndDrop(false);
                  }}
                  style={treeSorting == "DESC" ? { color: "#F94F5E" } : { color: "black" }}
                >
                  <Descending fill={treeSorting == "DESC" ? "#F94F5E" : "#939399"} />
                  Descending
                </span>
                <span
                  className="ml-2 ordering"
                  style={treeSorting == "CUSTOM" ? { color: "#F94F5E" } : { color: "black" }}
                  onClick={() => {
                    // setSort(3)
                    setTreeSorting("CUSTOM");
                    handleSorting({ id: 3, value: "CUSTOM" });
                    setChangeOrder(true);
                    setAllowDragAndDrop(true);
                  }}
                >
                  <Custom fill={treeSorting == "CUSTOM" ? "#F94F5E" : "#939399"} />
                  Custom Order
                </span>
              </div>
            </div>
          ) : null}
          <div className="pr-0 pl-0 ml-auto mr-2" style={{ maxWidth: "fitContent" }}>
            <div className="mata-content p-0" style={{ height: "50px", background: "#FAFAFA" }}>
              <ul className="mt-0">
                {accessActionAdd && (
                  <li className="cursor-pointer">
                    <span className="btn btn-primary pr-3 pt-1 pb-1" onClick={() => openTechnologyAddModal()}>
                      Add
                    </span>
                  </li>
                )}
                {accessActionEdit && (
                  <li className="cursor-pointer nopointer">
                    <span
                      className={`btn pl-3 pt-1 pb-1  
											 ${selectedTaxonomy ? "taxonomyCustom" : "taxonomyCustomDisable disabled"}`}
                      onClick={() => openTechnologyEditModal()}
                    >
                      Edit
                    </span>
                  </li>
                )}
                {accessActionDelete && (
                  <li className="cursor-pointer nopointer">
                    <span
                      className={`btn pl-3 pt-1 pb-1  
											 ${selectedTaxonomy ? "taxonomyCustom" : "disabled taxonomyCustomDisable"}`}
                      onClick={() => deleteTechnology()}
                    >
                      Delete
                    </span>
                  </li>
                )}
                {treeCollapse ? (
                  <li className="cursor-pointer">
                    <span className="btn  pl-3 pt-1 pb-1 taxonomyCustom" onClick={() => setTreeCollapse(false)}>
                      Expand All
                    </span>
                  </li>
                ) : (
                  <li className="cursor-pointer">
                    <span className="btn  pl-3 pt-1 pb-1 taxonomyCustom" onClick={() => setTreeCollapse(true)}>
                      Collapse All
                    </span>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row m-0 p-0 bg-white ">
        <div className="p-0 pt-3 treeScroll">
          {taxonomy.length > 0 ? (
            <div>
              <SortableTree
                treeData={taxonomy}
                onClick={handleClick}
                saveUpdatedTaxonomy={saveUpdatedTaxonomy}
                treeCollapse={treeCollapse}
                sort={treeSorting}
                allowDragAndDrop={allowDragAndDrop}
                setTaxonomy={setTaxonomy}
                selectedMedia={selectedTechnology}
                reRender={reRender}
                setReRender={setReRender}
                changeOrder={changeOrder}
                parentId={"dtParentId"}
              />
            </div>
          ) : null}
        </div>
        <div className="ml-auto">
          <div className="taxonomySidebar">
            <span
              className={`${showInstruction ? "invertedText-Color" : "invertedText"}`}
              onClick={() => {
                setToggle(true);
                setShow(true);
                setShowInstruction(true);
                setShowAddEdit(false);
              }}
            >
              {" "}
              Instructions
            </span>
            {showAddEdit && (
              <span
                className={`${showAddEdit ? "invertedText-Color" : "invertedText"}`}
                onClick={() => {
                  setToggle(true);
                  setShow(true);
                  setShowAddEdit(true);
                  setShowInstruction(false);
                }}
              >
                {" "}
                Options
              </span>
            )}
          </div>
        </div>
      </div>
      {showAdd && !showInstruction && (
        <SideBar
          toggle={toggle}
          show={show}
          setShow={setShow}
          showAddEdit={showAddEdit}
          handleSubmit={handleSubmitSaveTaxonomy}
          selectedNode={selectedTaxonomy}
          setShowAddEdit={setShowAddEdit}
          setShowInstruction={setShowInstruction}
        />
      )}

      {edit && !showInstruction && (
        <SideBar
          toggle={toggle}
          show={show}
          setShow={setShow}
          showAddEdit={showAddEdit}
          selectedTaxonomy={selectedTaxonomy}
          handleSubmit={handleSubmitUpdateTaxonomy}
          selectedNode={selectedTaxonomy}
          setShowAddEdit={setShowAddEdit}
          setShowInstruction={setShowInstruction}
        />
      )}

      {showInstruction && (
        <SideBar
          toggle={toggle}
          show={show}
          setShow={setShow}
          showInstruction={showInstruction}
          setShowAddEdit={setShowAddEdit}
          setShowInstruction={setShowInstruction}
        />
      )}
    </div>
  );
};

export default TaxomonyUI;
