/*================================================================
‘Copyright © 2024, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  OnePetro Page
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created			: 24/Mar/2025
  Author						: Satya Prakash Mall
================================================================ 
*/
import React, { useEffect } from "react";

export default () => {
  // ** UTILS **
  const checkEligiblity = async () => {
    const userAgent = navigator.userAgent.toLowerCase();
    let isBrave = false;
    // Check if Brave browser
    if (navigator.brave) {
      isBrave = await navigator.brave.isBrave();
    }
    let referrerPolicy = "";
    // Identify Browsers
    const isChrome = userAgent.includes("chrome") && !isBrave;
    const isFirefox = userAgent.includes("firefox");

    // Apply "unsafe-url" referrer policy for Chrome & Firefox (excluding Edge & Brave)
    if (isChrome || isFirefox) {
      referrerPolicy = "unsafe-url";
    }

    // Create a temporary <a> element
    const link = document.createElement("a");
    link.href = "https://onepetro.org";

    if (referrerPolicy) {
      link.referrerPolicy = referrerPolicy;
    } else {
      link.target = "_self";
    }

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // ** EFFECTS **
  useEffect(() => {
    checkEligiblity();
  }, []);

  return (
    <div
      className="gennx-content-wrapper content-wrapper px-3"
      style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%", flexDirection: "column" }}
    >
      <h4>OnePetro is now a standalone app.</h4>
      <h6>Please wait while we redirect and log you in.</h6>

      <button onClick={checkEligiblity}>Click here if you are not redirected.</button>
    </div>
  );
};
