import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import moment from "moment";
import { useFormik } from "formik";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { useDispatch, useSelector } from "react-redux";
import { Image } from "react-bootstrap";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { swap } from "../../../utilities";
import { fetchKeywordSearchReport, fetchKeywordDetailReport, fetchGroupList } from "../../../middleware/services/reportApi";
import CloseIcon from "../../../assets/images/closeIcon.svg?react";

import "./style.scss";
import CustomSidebar from "./SideBar";
import { StringFilter, NumberFilter } from "app/components/dataGrid/fliters";
import { hideLoader, showLoader } from "middleware/actions/utilityAction";
import DateFilter from "app/components/filterSection/dateFilterSearch";
import TreeDataGridNew from "app/components/treeDataGridNew";
import { ColumnsDirective, ColumnDirective } from "@syncfusion/ej2-react-treegrid";
import * as CONSTANTS from "../../../constants/index";
import { presets } from "utils";
import { Dropdown, TextField } from "../../components/CustomFormElements/syncFusionFormFields";
import emptyImage from "../../../assets/images/images/empty.svg";

let userCompetencyIndustry =
  localStorage.getItem("userCompetencyIndustry") !== "null" ? JSON.parse(localStorage.getItem("userCompetencyIndustry")) : [];

// specify breadcrumb here
const breadCrumbLinks = [{ linkUrl: "/report/internal-user", linkName: "Internal User Report", linkActive: true }];
// enableRipple(true);

const TimeTemplate = (value) => {
  const column = value.column.field;
  if (value[`${column}`]) return <span>{value[`${column}`]} Sec</span>;
  return "NA";
};

const DateTemplate = (value) => {
  const column = value.column.field;
  return ["searchCreationDate"].includes(column) && !isNaN(Date.parse(value[`${column}`])) ? <span>{formatDate(value[`${column}`])}</span> : "";
};

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const options = {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: "Asia/Kolkata", // Change the timeZone to the desired time zone
  };

  const formattedDate = new Intl.DateTimeFormat("en-IN", options).format(date);

  // Use string manipulation to capitalize "AM" and "PM"
  const capitalizedDate = formattedDate.replace(/(am|pm)/gi, (match) => match.toUpperCase()).replace(",", "");

  return capitalizedDate;
};

const SiderBarGrid = ({ onclose, fetch }) => {
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState([]);
  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());

  const columnFields = [
    {
      field: "groupName",
      headerText: "Group",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "180px",
      filterTemplate: StringFilter,
      showInColumnChooser: false,
    },
    {
      field: "userEmail",
      headerText: "Searched By",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "180px",
      filterTemplate: StringFilter,
      showInColumnChooser: false,
    },
    {
      field: "searchKeywords",
      headerText: "Searched Keyword",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "255px",
      filterTemplate: StringFilter,
    },
    {
      field: "searchCreationDate",
      headerText: "Date and Time",
      type: "Date",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "120px",
      filterTemplate: StringFilter,
      template: DateTemplate,
    },
    {
      field: "searchNumberOfResultsViewed",
      headerText: "Results Viewed",
      type: "Number",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "130px",
      filterTemplate: NumberFilter,
    },
    {
      field: "searchResultTime",
      headerText: "Time taken (Approx)",
      type: "Number",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "140px",
      filterTemplate: NumberFilter,
      template: TimeTemplate,
    },
    {
      field: "searchDeviceType",
      headerText: "Device",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "75px",
      filterTemplate: StringFilter,
    },
    {
      field: "searchIsAddedToSavesearch",
      headerText: "Search Saved",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "110px",
      filterTemplate: StringFilter,
    },
  ];

  const fetchUsersData = useCallback(async () => {
    try {
      showLoaderGrid();
      const response = await fetch();
      if (response?.data?.data.length > 0) setRowData(response?.data?.data);
      hideLoaderGrid();
    } catch (error) {
      hideLoaderGrid();
      console.error("Error fetching data:", error);
    }
  }, []);

  useEffect(() => {
    fetchUsersData();
  }, []);

  return (
    <React.Fragment>
      <div className="gennx-grid-client-detail-report client-details-user-grid" style={{ height: "100%" }}>
        <header className="user-grid-header">
          <span className="header-text">Detail View</span>
          <span
            className="closeIcon"
            onClick={() => {
              onclose();
            }}
          >
            <CloseIcon />
          </span>
        </header>
        {rowData.length > 0 && (
          <TreeDataGridNew
            gridTitle="Keyword Search Report Details"
            rowData={rowData}
            isPaging={false}
            showResetFilter={true}
            deleteMany={false}
            allowPagination={true}
            showExportButton={true}
            exportFileName={"Keyword Search Report - Detail View"}
          >
            <ColumnsDirective>
              {columnFields.map((column, index) => (
                <ColumnDirective key={index} {...column} />
              ))}
            </ColumnsDirective>
          </TreeDataGridNew>
        )}
      </div>
    </React.Fragment>
  );
};
// company dashboard component
const KeywordSearch = (props) => {
  let inputBoxRef = useRef();
  const [users, setUsers] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [reportDetailsDateRange, setReportDetailsDateRange] = useState(null);
  const [functionClick, setFunctionOnclick] = useState(undefined);
  const [startDate, setStartDate] = useState(moment(new Date()).format("dd-MMM-yyyy"));
  const [endDate, setEndDate] = useState(moment(new Date()).format("dd-MMM-yyyy"));
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [sideBarFilters, setSideBarFilters] = useState([]);
  const [gridRef, setGridRef] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [keywordsText, setKeywordsText] = useState("");
  const sidebarRef = useRef(null);
  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());
  const [totalCount, setTotalCount] = useState(0);
  const gridState = useSelector((state) => state.gridState);
  const [isUpdated, setIsUpdated] = useState(undefined);
  const [groupDataSource, setGroupDataSource] = useState([]);

  const toggleSidebar = (value, fieldName, keyword, groupName, groupNameHidden) => {
    let filters = [];
    if (keyword) filters.push(["search", "eq", keyword.split(",").map((word) => word.trim())]);
    else filters.push(["search", "eq", ""]);

    filters.push(["company", "eq", groupNameHidden]);

    filters.push(["fieldName", "eq", fieldName]);
    setSideBarFilters([...filters]);

    if (sidebarRef.current) {
      sidebarRef.current.hide();
    }
    setShowSidePanel(!showSidePanel);
  };

  const fetchKeywordReport = useCallback(
    (params = {}) => {
      const { filters = [] } = params;
      const reportDateRange = fnStartDate();

      const DateFilter = reportDetailsDateRange === null ? reportDateRange : reportDetailsDateRange;

      if (DateFilter) {
        let gte = { d: DateFilter[0] };
        Date.prototype.toJSON = function () {
          return moment(this).format();
        };
        gte.d.toJSON = function () {
          return moment(this).format();
        };

        let lt = { d: moment(DateFilter[1]) };
        lt.d.toJSON = function () {
          return moment(this).format();
        };

        filters.push(["searchCreationDate", "gte", gte.d]);
        filters.push(["searchCreationDate", "lt", lt.d]);
      }

      params.filters = [...sideBarFilters, ...filters];
      return fetchKeywordDetailReport({ ...params });
    },
    [sideBarFilters]
  );

  const initialState = {
    reportGroup: "All",
    reportKeyword: "",
  };
  const fnStartDate = () => {
    let dateFrom = new Date();
    let dateTo = new Date();
    dateFrom.setDate(dateFrom.getDate() - CONSTANTS.KEYWORD_REPORTS_DEFAULT_DATE_RANGE);
    const dateStart = dateFrom
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");
    dateTo.setDate(dateTo.getDate());
    const dateEnd = dateTo
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");
    setStartDate(new Date(dateStart));
    setEndDate(new Date(dateEnd));
    return [dateFrom, dateTo];
  };

  const formik = useFormik({
    initialValues: {
      ...initialState,
    },
    // validationSchema: Yup.object().shape(fundingValidationSchema)
  });
  const dispatch = useDispatch();

  const rowDataBound = (args) => {
    if (args.data.level == 0) {
      args.row.childNodes[0].style.fontWeight = "600";
    }
    (args.row?.childNodes[0]).style.fontSize = "14px";
    (args.row?.childNodes[0]).style.textAlign = "left";
  };

  const recordsTemplate = (value) => {
    const a = value[`${value.column.field}`];
    return a ? (
      <span
        className={value.keyword === "" ? "span-link-color span-link-weight" : "span-link-color"}
        onClick={() => toggleSidebar(a, value.column.field, value["keyword"], value["groupName"], value["taskData"]["groupNameHidden"])}
      >
        {a}
      </span>
    ) : (
      <span className={value.keyword === "" ? "span-link-weight" : ""}>{a}</span>
    );
  };

  const searchKeywords = (index) => {
    let text = inputBoxRef.current.element.value;
    setKeywordsText(text);
  };

  // specify column names for column fields in datGrid here
  const columnNames = {
    "Sr No": "srNo",
    Group: "groupName",
  };
  const columnFields = [
    {
      field: "groupName",
      headerText: "Group",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "170px",
      filterTemplate: StringFilter,
      allowFiltering: false,
      allowSorting: false,
      showInColumnChooser: false,
    },
    {
      field: "keyword",
      headerText: "Keyword",
      type: "String",
      textAlign: "Left",
      headerTextAlign: "Left",
      width: "270px",
      filterTemplate: StringFilter,
      allowFiltering: false,
      allowSorting: false,
      showInColumnChooser: false,
    },
    {
      field: "keywordSearchCount",
      headerText: "Search Count",
      type: "Number",
      textAlign: "Center",
      headerTextAlign: "Left",
      width: "80px",
      allowFiltering: true,
      allowSorting: false,
      template: recordsTemplate,
    },
    {
      field: "resultsViewed",
      headerText: "Results Viewed",
      type: "Number",
      textAlign: "Center",
      headerTextAlign: "Left",
      width: "75px",
      allowFiltering: true,
      allowSorting: false,
      template: recordsTemplate,
    },
    {
      field: "addedToSavedSearch",
      headerText: "Saved Search",
      type: "Number",
      textAlign: "Center",
      headerTextAlign: "Left",
      width: "75px",
      allowFiltering: true,
      allowSorting: false,
      template: recordsTemplate,
    },
  ];

  const fetchGroups = useCallback(async () => {
    try {
      const response = await fetchGroupList();
      setGroupDataSource(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  const fetchKeywordSearchReports = async (reportDateRange, params = {}) => {
    try {
      showLoaderGrid();
      let filters = [];
      filters.push(["groupName", "eq", formik.values.reportGroup]);
      filters.push(["reportKeyword", "eq", formik.values.reportKeyword]);
      // if (gridState?.recordsPerPage) params.limit = gridState.recordsPerPage;
      const DateFilter = dateRange === null ? reportDateRange : dateRange;
      setReportDetailsDateRange(DateFilter);

      if (DateFilter) {
        let gte = { d: DateFilter[0] };
        Date.prototype.toJSON = function () {
          return moment(this).format();
        };
        gte.d.toJSON = function () {
          return moment(this).format();
        };

        let lt = { d: moment(DateFilter[1]) };
        lt.d.toJSON = function () {
          return moment(this).format();
        };

        filters.push(["searchCreationDate", "gte", gte.d]);
        filters.push(["searchCreationDate", "lt", lt.d]);
      }
      params.filters = [...filters];
      let response = await fetchKeywordSearchReport({ ...params });
      setRowData(response?.data.data);
      setTotalCount(response?.data.totalCount);
      hideLoaderGrid();
    } catch (e) {
      console.log("err :::::::", e);
      hideLoaderGrid();
    }
  };

  const handleReset = () => {
    window.location.reload();
    // setSelectedUser(undefined);
    // setDateRange(null);
    // localStorage.removeItem("dateRange2");
    // fetchReportInternalUserWithProductVariant().then((res) => {
    //   if (res.data) setIsUpdated(Date.now());
    // });
  };

  const groupsDataSource = useMemo(() => {
    let groupData = groupDataSource?.map((group) => {
      return { label: group.groupName, value: group.groupName };
    });
    groupData.unshift({ label: "All", value: "All" });
    return groupData;
  }, [groupDataSource]);

  useEffect(() => {
    fetchGroups();
    const reportDateRange = fnStartDate();
    fetchKeywordSearchReports(reportDateRange);
  }, []);

  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => {
      dispatch(setBreadCrumb());
    };
  }, [dispatch]);

  const getGridRef = (gridRefData) => {
    setGridRef(gridRefData);
  };

  const handleCreated = () => {
    showLoaderGrid();
    if (gridRef?.current) {
      gridRef.current.grid.columnChooserSettings.operator = "contains";
    }
    hideLoaderGrid();
  };

  return (
    <div className="px-3 grid-bg-white">
      <div className="col-md-12 rounded-bottom nopadding p-0 bg-white">
        <div className="row">
          <div className="col-md-2 mt-3 grid-container-report-filters pr-0 keyword-search-report-datepicker">
            <DateRangePickerComponent
              placeholder="Date Range"
              cssClass="e-outline analyst-query-datepicker"
              openOnFocus={true}
              showClearButton={false}
              format="dd MMM yyyy"
              separator="to"
              floatLabelType="Auto"
              startDate={startDate}
              endDate={endDate}
              onChange={(e) => {
                if (e.value) setDateRange(e.value);
              }}
              autoComplete="off"
              max={new Date()}
              maxDays={CONSTANTS.KEYWORD_SEARCH_REPORT_DATE_RANGE_MAX}
              strictMode={true}
              presets={presets}
            />
          </div>
          <div className="col-md-2 mt-3 pr-0">
            <Dropdown
              formik={formik}
              placeholder="Group"
              required="required"
              id="reportGroup"
              name="reportGroup"
              fields={{ text: "label", value: "value" }}
              value={formik.values.reportGroup}
              dataSource={groupsDataSource}
            />
          </div>
          <div className="col-md-2 mt-3 pr-0 keyword-search-report-search-box">
            <TextField formik={formik} type="text" id="reportKeyword" placeholder="Filter by Keyword" name="reportKeyword" />
          </div>
          <div className="col-md-3 mt-3">
            <button
              type="button"
              onClick={() => {
                const reportDateRange = fnStartDate();
                fetchKeywordSearchReports(reportDateRange);
              }}
              className="search-btn px-4 btn btn-primary mr-2"
            >
              Search
            </button>
            <button type="reset" onClick={handleReset} className="btn btn-outline-dark px-4 ml-2 ">
              Reset
            </button>
          </div>
        </div>
      </div>
      <div className="gennx-grid-client-detail-report">
        <div className="row">
          <div className="col-md-8 keyword-search-report">
            {rowData?.length > 0 ? (
              <TreeDataGridNew
                gridTitle="Keyword Search Report"
                rowData={rowData}
                isPaging={false}
                showResetFilter={true}
                deleteMany={false}
                functionClick={functionClick}
                showLoader={showLoaderGrid}
                hideLoader={hideLoaderGrid}
                allowPagination={false}
                showExportButton={true}
                exportFileName={"Keyword Search Report"}
                rowDataBound={rowDataBound}
                getGridRef={getGridRef}
                created={handleCreated}
              >
                <ColumnsDirective>
                  {columnFields.map((column, index) => (
                    <ColumnDirective key={index} {...column} />
                  ))}
                </ColumnsDirective>
              </TreeDataGridNew>
            ) : (
              rowData && (
                <div className="noKeyPlayerImage">
                  <Image src={emptyImage} alt="No data available" />
                  <p style={{ fontSize: "15px", fontWeight: "bold" }}>No Data Available</p>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      {showSidePanel && (
        <CustomSidebar sidebarRef={sidebarRef} isOpen={showSidePanel}>
          <SiderBarGrid onclose={toggleSidebar} fetch={fetchKeywordReport} />
        </CustomSidebar>
      )}
    </div>
  );
};

export default KeywordSearch;
