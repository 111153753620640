/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Company Overview elements integrations 
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 08/Aug/2020 
	Author						: Aditya Tijare
--------------------------------------------------------------------------------- 
	Change History #1 
	Date Modified : 12/Oct/2021
	Changed By : Sai
	Change Description : Changed setstate for companyParentLabel,companyParentValue,companyAcquiredBy,companyAcquiredByValue,companyAcquiredBy
	Ticket Ref Number  : #6049
	Reason for Change  : changing components to syncfusion react
	Updated Code       : setstate for companyParentLabel,companyParentValue,companyAcquiredBy,companyAcquiredByValue,companyAcquiredBy
================================================================ 
*/

import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "../../../../components/accordion";
import Overview from "./overview";
import { actionSuccess, showLoader, hideLoader, actionError } from "../../../../../middleware/actions/utilityAction";
import { setOverviewUpdateStatus, setClickSaveSubmitState } from "../../../../../middleware/actions/companyAction";
import {
  saveOverview,
  fetchOverview,
  fetchCompanyLogoUrl,
  updateOverview,
  fetchCompanySource,
  saveCompanyAdvanced,
} from "../../../../../middleware/services/companyApi";
import { getLoggedInUser, getProductVariant } from "../../../../../utilities";

const CompanyOverview = (props) => {
  const companyId = props.companyId || "";
  const [companyInformation, setCompanyInformation] = useState({
    companyName: "",
    companyStatus: "",
    companyParent: "",
    companyAcquiredBy: "",
    companyLegalStatus: "",
    companyIsStartup: "",
    companyFoundedYear: "",
    companyEmpSize: "",
    companySocialTwitter: "",
    companySocialFacebook: "",
    companySocialLinkedIn: "",
    companyEmail: "",
    companyWebsite: "",
    companyWorkInProgress: "",
    companyPaywallSetting: "NO",
    companyPublishedDate: "",
  });
  const [companySource, setCompanySource] = useState({
    totalCount: 0,
    data: [],
  });
  const [loading, setLoading] = useState(companyId == "" ? false : true);
  const [companyLogo, setCompanyLogo] = useState("");
  const [companyPaywallData, setCompanyPaywallData] = useState([]);
  const clickedSaveSubmit = useSelector((state) => state.companyState.clickStatus);
  const dispatch = useDispatch();
  const productVariant = getProductVariant();
  const commonState = useSelector((state) => state.companyState);
  const user = getLoggedInUser();
  useEffect(() => {
    if (companyId != "") {
      fetchOverviewData();
      fetchCompanySourceData();
    }
    dispatch(setOverviewUpdateStatus(false));
  }, []);

  const fetchOverviewData = async () => {
    dispatch(showLoader());
    const response = await fetchOverview(companyId);
    if (response) {
      const val = response.data.data;
      val.companyPaywallSetting && setCompanyPaywallData(val.companyPaywallSetting);
      let companyPaywallSetting = "NO";
      val.companyPaywallSetting &&
        val.companyPaywallSetting.map((elem) => {
          if (elem.productVariant == productVariant) {
            companyPaywallSetting = "YES";
          }
        });
      setCompanyInformation({
        companyName: props.basicFetched ? companyInformation.companyName : val.companyName,
        companyStatus: val.companyStatus ? val.companyStatus.value : "",
        companyParent: val.companyParent && val.companyParent.companyName ? val.companyParent.companyName : "",
        companyParentLabel: val.companyParent && val.companyParent.companyName ? val.companyParent.companyName : "",
        companyParentValue: val.companyParent && val.companyParent.companyId ? val.companyParent.companyId : "",
        companyAcquiredBy: val.companyStatus && val.companyStatus.acquiredBy ? val.companyStatus.acquiredBy.companyName : "",
        companyAcquiredByLabel: val.companyStatus && val.companyStatus.acquiredBy ? val.companyStatus.acquiredBy.companyName : "",
        companyAcquiredByValue: val.companyStatus && val.companyStatus.acquiredBy ? val.companyStatus.acquiredBy.companyId : "",
        companyLegalStatus: val.companyLegalStatus || "",
        companyIsStartup: val.companyIsStartup,
        companyFoundedYear: val.companyFoundedYear || "",
        companyEmpSize: val.companyEmpSize || "",
        companySocialTwitter: val.companySocial ? val.companySocial.twitter : "",
        companySocialFacebook: val.companySocial ? val.companySocial.facebook : "",
        companySocialLinkedIn: val.companySocial ? val.companySocial.linkedIn : "",
        companyEmail: val.companyEmail || "",
        companyWebsite: val.companyWebsite || "",
        companyWorkInProgress: val.companyWorkInProgress || "",
        companyPaywallSetting: companyPaywallSetting,
        companyPublishedDate: val.productVariant.find((item) => item.productName === productVariant).lastPublishedDate || new Date(),
      });

      props.setBasicFetched(true);
      if (typeof val.companyLogo != "undefined" && val.companyLogo != "") {
        let img = await fetchCompanyLogoUrl(companyId, val.companyLogo);
        setCompanyLogo(img);
      }
    }
    dispatch(hideLoader());
    setLoading(false);
  };

  const fetchCompanySourceData = async () => {
    const param = [];
    param.push(["companyId", "eq", companyId]);
    dispatch(showLoader());
    try {
      const response = await fetchCompanySource(JSON.stringify(param));
      dispatch(hideLoader());

      if (response.status == 200) {
        setCompanySource(response.data);
      }
    } catch (e) {
      dispatch(hideLoader());
    }

    setLoading(false);
  };

  // save a Overview
  const handleSaveOverview = async (saveOverviewData, editImage) => {
    dispatch(showLoader());
    if (companyId != "") {
      saveOverviewData.append("selectedProductVariant", getProductVariant());
      await updateOverview(companyId, saveOverviewData)
        .then((response) => {
          if (clickedSaveSubmit) {
            setTimeout(() => {
              dispatch(setOverviewUpdateStatus(true));
              dispatch(setClickSaveSubmitState());
            }, 500);
          }
          dispatch(hideLoader());
          if (editImage === true) {
            if (saveOverviewData.has("companyLogo")) {
              dispatch(actionSuccess("Image Added Successfully"));
            }
          } else {
            handleCommonSaveAd();
            dispatch(actionSuccess("Company Updated Successfully"));
          }
        })
        .catch((err) => {
          console.error(err);
          dispatch(actionError(err?.data?.message || "Something went wrong"));
        });
    } else {
      try {
        const productVariant = getProductVariant();
        if (productVariant) saveOverviewData.append("productVariant", JSON.stringify([{ productName: productVariant, isLive: "NO" }]));
        saveOverviewData.append("userId", user._id);
        saveOverviewData.append("companyCreatedByUserId", user._id);
        saveOverviewData.append("companyCreatedByUserName", user.user_fname + " " + user.user_lname);
        const response = await saveOverview(companyId, saveOverviewData);
        fetchOverviewData();
        dispatch(hideLoader());
        if (response.status == 200) {
          if (typeof response.data.data.id != "undefined") {
            dispatch(actionSuccess("Company Info Saved Successfully"));
            props.history.push(`${response.data.data.id}/edit#basic`);
            props.newRecord();
          }
        }
      } catch (e) {
        dispatch(hideLoader());
        if (e.status == 409) {
          dispatch(actionError(e.data.message));
        }
      }
    }
  };

  const handleCommonSaveAd = (id) => {
    dispatch(showLoader());
    let advanceTabDetails = commonState?.advanceDetails || {};
    if (commonState?.advanceDetails?.companyAbout?.length) {
      try {
        if (Object.keys(advanceTabDetails?.length > 0)) {
          let payload = {};
          payload.companyAbout = advanceTabDetails?.companyAbout || "";
          payload.companyBusinessModel = advanceTabDetails?.companyBusinessModel || "";
          payload.companyFuturePlans = advanceTabDetails?.companyFuturePlans || "";
          payload.companyWhatsUnique = advanceTabDetails?.companyWhatsUnique || "";
          payload.id = companyId;

          const data = Object.keys(payload).reduce((formData, key) => {
            formData.append(key, payload[key]);
            return formData;
          }, new FormData());

          saveCompanyAdvanced(companyId, data)
            .then((res) => {
              dispatch(actionSuccess("Company advanced info saved successfully"));
              dispatch(setClickSaveSubmitState());
            })
            .catch((err) => {
              dispatch(actionError("Something went wrong"));
            });
        }
      } catch {
        dispatch(actionError("Something went wrong"));
      }
    }
  };

  useEffect(() => {
    if (!companyId) {
      setCompanyInformation({ ...companyInformation, companyPublishedDate: new Date() });
    }
  }, []);

  return (
    <Accordion
      heading="Overview"
      step={1}
      stepCompleted={companyId !== "" ? true : false}
      activeState={companyId !== "" ? false : true}
      style={{ background: "#DBDBDD" }}
    >
      <Overview
        companyInformation={companyInformation}
        companyLogo={companyLogo}
        companySource={companySource}
        companyPaywallData={companyPaywallData}
        handleSaveOverview={handleSaveOverview}
        handleCommonSaveAd={props.handleCommonSaveAd}
        editData={props.editData}
        {...props}
      />
    </Accordion>
  );
};

export default withRouter(CompanyOverview);
