/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : Company basic tab action buttons
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 25/Jun/2020 
    Author						: Anand Kumar
================================================================ 
*/

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { actionSuccess, actionError } from "../../../../../middleware/actions/utilityAction";
import { setClickSaveSubmitState, setOverviewUpdateStatus } from "../../../../../middleware/actions/companyAction";
import { useDispatch, useSelector } from "react-redux";
import { accessRightActionCheck, getLoggedInUser } from "../../../../../utilities";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import { processingSubmit, processingFinalSubmit } from "../../../../../middleware/services/cmsApi";
import CompanyDataPublicUI from "../../../dataPublishing/company/actionButton";
import "../../../../components/popup/index.scss";
import { COMMON_ACCESS_ACTION } from "../../../../../constants";
import PropTypes from "prop-types";

const ActionButtons = (props) => {
  const { accessRights: aR } = props;
  const dispatch = useDispatch();
  const companyId = props.companyId;
  const loggedInUser = getLoggedInUser();
  const updateStatus = useSelector((state) => state.companyState.updateStatus);
  const clickStatus = useSelector((state) => state.companyState.clickStatus);
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  /* Interface action access from common access */
  useEffect(() => {
    let interfaceName = Object.values(props?.hashArr)[props.selectedIndex];
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, [props.selectedIndex]);

  /* Common access */
  const accessActionSubmit = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SUBMIT);
  const accessActionFinalSubmit = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.FINAL_SUBMIT);
  const accessActionPublish = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.PUBLISH);

  useEffect(() => {
    if (updateStatus && props.selectedIndex === 0) {
      dispatch(
        showAlertBox({
          okCallback: async () => {
            processingSubmit(companyId, "basic", { userId: loggedInUser._id })
              .then((response) => {
                dispatch(actionSuccess(`Company successfully Saved & Submitted`));
              })
              .catch((err) => {
                console.log(err?.data?.message);
                dispatch(actionError(err?.data?.message || "Something went wrong"));
              });
            dispatch(setClickSaveSubmitState());
            dispatch(setOverviewUpdateStatus(false));
          },
          cancelCallback: () => {
            dispatch(setClickSaveSubmitState());
            dispatch(setOverviewUpdateStatus(false));
          },
          okText: "Submit",
          cancelText: "Cancel",
          content: "Are you sure you want to submit ? ",
          title: "dialogAlertCss",
        })
      );
    }
  }, [updateStatus]);
  return (
    <div className="container-fluid nopadding">
      <div className="row">
        <div className="float-LEFT col-md-4 text-left">
          {props.selectedIndex > -1 ? (
            <span className="pl-4" style={{ marginLeft: "-3em" }}>
              <span className="span-link ">
                <button
                  type="button"
                  className="cy-test-btn btn btn-outline-dark cy-btn pl-4 pr-4 "
                  onClick={() => {
                    props.selectedIndex < 4 && props.setCurrentTab(props.selectedIndex + 1);
                  }}
                >
                  Next
                </button>
              </span>
            </span>
          ) : null}
        </div>
        <div className="float-right col-md-8 text-right">
          <Link to="/company">
            <button type="button" className="btn btn-outline-dark cy-btn pl-4 pr-4 mr-3">
              Cancel
            </button>
          </Link>
          {accessActionSubmit ? (
            <button
              type="button"
              className="btn btn-primary cy-btn pl-2 pr-2 mr-3"
              onClick={() => {
                if (clickStatus) dispatch(setClickSaveSubmitState());
                else dispatch(setClickSaveSubmitState(true));
              }}
            >
              Submit
            </button>
          ) : null}
          {accessActionFinalSubmit ? (
            <button
              type="button"
              className="btn btn-primary cy-btn pl-4 pr-4 "
              onClick={() => {
                dispatch(
                  showAlertBox({
                    okCallback: async () => {
                      processingFinalSubmit(companyId, {
                        userId: loggedInUser._id,
                      })
                        .then((response) => {
                          dispatch(actionSuccess("Company successfully Final Submitted."));
                        })
                        .catch((err) => {
                          console.log(err?.data?.message);
                          dispatch(actionError(err?.data?.message || "Something went wrong"));
                        });
                    },
                    okText: "Submit",
                    cancelText: "Cancel",
                    content: "Are you sure you want to submit ? ",
                    title: "dialogAlertCss",
                  })
                );
              }}
            >
              Final Submit
            </button>
          ) : null}
          {accessActionPublish ? <CompanyDataPublicUI companyId={companyId} /> : null}
        </div>
      </div>
    </div>
  );
};
ActionButtons.propTypes = {
  accessRights: PropTypes.arrayOf(PropTypes.string),
  hashArr: PropTypes.array,
  companyId: PropTypes.string,
  selectedIndex: PropTypes.number,
  setCurrentTab: PropTypes.func,
};

export default ActionButtons;
