/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : Company Images  UI for  Listing, add and delete and API wiring up
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 30/Jul/2020 
    Author						: Anand Kumar
================================================================ 
*/

import React, { useState, useEffect } from "react";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import { useDispatch } from "react-redux";
import { actionSuccess, actionError } from "../../../../../middleware/actions/utilityAction";
import { fetchImage, deleteImage, fetchImageURL } from "../../../../../middleware/services/companyApi";
import ImageModal from "./imagesModal";
import Accordion from "../../../../components/accordion";
import { COMMON_ACCESS_ACTION } from "../../../../../constants";
import PropTypes from "prop-types";

const ImageComponent = (props) => {
  /* Common access */
  const accessPermissionAdd = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_COMPANY_IMAGES);
  const accessPermissionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE_IMAGES);

  const dispatch = useDispatch();
  const [editImageData, setEditImageData] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [imageList, setImageList] = useState([]);

  const getImages = async () => {
    const response = await fetchImage(props.companyId);
    setImageList(response.data?.data?.companyImages || []);
  };
  useEffect(() => {
    getImages();
  }, []);
  return (
    <>
      <Accordion heading="Images" stepCompleted={imageList && imageList.length > 0} step={6}>
        <div className="row p-3">
          <div className="col-md-12">
            <div className="image-wrapper">
              {imageList.map((item) => {
                return (
                  <div className="image-holder" key={`pimg-${item.id}`}>
                    <img src={fetchImageURL(item.imgFileName)} alt={item.imgName} />
                    <div className="image-action">
                      {accessPermissionDelete ? (
                        <>
                          <i
                            className="fa fa-trash mr-1 "
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(
                                showAlertBox({
                                  okCallback: async () => {
                                    await deleteImage(props.companyId, item.id)
                                      .then((response) => {
                                        if (response) {
                                          dispatch(actionSuccess("Image deleted successfully"));
                                          getImages();
                                        } else {
                                          dispatch(actionError("Something went wrong"));
                                        }
                                      })
                                      .catch((err) => {
                                        dispatch(actionError(err?.data?.message || "Something went wrong"));
                                      });
                                  },
                                  okText: "Delete",
                                  cancelText: "Cancel",
                                  content: "Are you sure you want to Delete ?",
                                  title: "dialogAlertCssWarning",
                                })
                              );
                            }}
                            aria-hidden="true"
                          />
                          {/* <i className="fa fa-pencil-alt " aria-hidden="true" /> */}
                        </>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="text-right">
          {accessPermissionAdd ? (
            <button
              type="button"
              className="btn btn-dark cy-btn"
              onClick={() => {
                setShowModal(!showModal);
                setEditImageData(false);
              }}
            >
              Add New
            </button>
          ) : null}
        </div>
      </Accordion>
      <ImageModal
        editImageData={editImageData}
        showModal={showModal}
        companyId={props.companyId}
        clicked={() => {
          setShowModal(!showModal);
          getImages();
        }}
      />
    </>
  );
};
ImageComponent.propTypes = {
  interfaceActionAccess: PropTypes.bool,
  companyId: PropTypes.number,
};
export default ImageComponent;
